import React from 'react'
import { Footer, Navbar } from "../components";

import {img} from "react-bootstrap/Image";

import AboutUsImage from '../images/about us.png';

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="row">
    <div className="col-12 ">
    
     <img className="card-img-top img-fluid" src={AboutUsImage} alt="" height={160} />
      </div> 
      </div>
      <div className="container my-3 py-3">
      
      <h2>Greetings User</h2>
            <h3>Refund Policy</h3>
            <div class="terms-text">
            <p className="lead text-start">All the products are non-returnable and all the payments are non-refundable.</p>
            </div>
        </div>
      <Footer />
    </>
  )
}

export default RefundPolicy