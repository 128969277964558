import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link, useNavigate, NavLink } from "react-router-dom";
//import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';
//import GooglePayButton from '@google-pay/button-react';
import { Checkbox, FormCheck } from 'react-bootstrap';
import Congratulations from "./Congratulations";
import Failure from "./Failure";
import emailjs from "@emailjs/browser";
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useAuth0 } from "@auth0/auth0-react";
import { useRef } from 'react';


//import Razorpay from "razorpay";


const CheckoutProduct = () => {
  const [isLoading, setLoading] = useState("");
  const [resRazorPaySDK, setresRazorPaySDK] = useState("");
  //SetLocalStorageFlag
  const [localStorageFlag, SetLocalStorageFlag] = useState("");
  const { isAuthenticated, logout } = useAuth0();
 
  const navigate = useNavigate();
  const [message, setMessage] = useState('');

 
  const state = useSelector((state) => state.handleCart);


  const [firstName, setFirstName] = useState();
  const [cvvValue, setCvvValue] = useState();
  //emailMessage
  const [emailMessage, setEmailMessage] = useState("");
  const [timer, setTimer] = useState(null)
  const [checked, setChecked] = React.useState(false);
  const [saveDB, setSaveDB] = React.useState(false);
  const [paymentSuccessFlag, setPaymentSuccessFlag] = React.useState(false);
  const [printOrderDataFlag, setPrintOrderDataFlag] = React.useState(false);
  const [displayCongratulation, SetDisplayCongratulation] = React.useState(false);
  //   dateofPayment:"28/10/2023",
  //   printOrderId:"PRI1001"
  const [printOrderIdVal, setprintOrderIdVal] = useState();
  const[totalCost, settotalCost] = useState(123);

  //const serverCreate = "https://nodejsrazorpay.onrender.com/razorpay";
  const serverCreate = "https://nodejsserver.online/razorpay";
 
  const item = [];
  const [personalInfo, setPersonalInfo] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    country: "India",
    state: "WestBengal",
    zip: "",
    cardHolderName: "",
    cardNumber: "",
    expiration: "",
    cVV: "",
    mobileNo: "",
    landmark: ""

  })
   

  useEffect(() => {
    emailjs.init("xkVfdOdrH64JDAp3E");
    setresRazorPaySDK(loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  ));
    
  }, [])
  

  // Test Code to all back end API of Node js....
/*
  useEffect(() => {
    fetch('/api')
      .then((res) => res.json())
      .then((data) => {
        setMessage(data.message);
        });
      
  }, []);

  */

// @@@@@@@@@@@@  Code to call savePaymentsData Starts below @@@@@@@@@@@@@@@@@@@@@@@@@@@@//

var templateParams = {
  message : "Hi..",
  name: 'Hi ' + personalInfo.firstName,
  subject: "This is order confirmation ref id: " + printOrderIdVal,
  to_mail: personalInfo.email
 };
 
const sendMailtoUser =(props)=>{

  //console.log("Ln 75 sendMailtoUser called.. props.emailMessage = " + props.emailMessage);
  templateParams.message = props.emailMessage;
  //console.log("Ln 77 sendMailtoUser called.. templateParams.message = " + templateParams.message);
    emailjs.send('service_5l4zgi1','sunder_template_i9kyzm1', templateParams, 'xkVfdOdrH64JDAp3E')
    .then(function(response) {
       //console.log('Email Send SUCCESS!', response.status, response.text);
    }, function(error) {
       //console.log('FAILED to send mail...', error);
    });

}
// @@@@@@@@@@@@ Code to call SAVEPaymentsData ends above  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
  const savePaymentsData =async(razorpayPaymentId, paidAmount)=>{
 
    //console.log("Ln 106 savePaymentsData called.. ");
    try{
     ///console.log("Inside SavePaymentsData : Ln 108, personalInfo.firstName = " + personalInfo.firstName );
     const totalPrice = JSON.parse(localStorage.getItem('Price'));
     const isFullPaymentDone = JSON.parse(localStorage.getItem('IsFullPaid'));
     var paidAmount = 0;
     if(isFullPaymentDone === true){
      paidAmount = JSON.parse(localStorage.getItem('AdvancePaymentFull'));
 
     }else{
      paidAmount = JSON.parse(localStorage.getItem('AdvancePayment'));
     }
     
      /* var paymentdate = JSON.stringify({
        transactionId: razorpayPaymentId.toString(),
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        email: personalInfo.email,
        mobileNo: personalInfo.mobileNo,
        address1: personalInfo.address1,
        country: "India",
        state: "West Bengal",
        zip: personalInfo.zip,
        totalPrice: totalPrice.toString(),
        payment: paidAmount.toString(),
        due:(totalPrice-advancePayment).toString(),
        dateofPayment:new Date().toLocaleString(),
        printOrderId:props.razorpayOrderId.toString()}); //Save print order id..
        
        console.dir("Payment Data = " + paymentdate);
        */
      let res = await fetch("https://printsundar.online/loginAPI/addpayment", {
        mode: 'cors',
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'charset': 'utf-8' },
        body: JSON.stringify({
          transactionId: razorpayPaymentId.toString(),
          firstName: personalInfo.firstName,
          lastName: personalInfo.lastName,
          email: personalInfo.email,
          mobileNo: personalInfo.mobileNo,
          address1: personalInfo.address1,
          country: "India",
          state: "West Bengal",
          zip: personalInfo.zip,
          totalPrice: totalPrice.toString(),
          payment: paidAmount.toString(),
          due:"0",
          dateofPayment:new Date().toLocaleString(),
          printOrderId:""
        }),
      });
      
      let resJson = await res.json();
      //console.log("Ln 133 return from addpayment" + resJson.status);
      //setShowProgres(false);
      if (res.status === 201 || res.status === 200) {
        savePrintOrderData(razorpayPaymentId.toString(), paidAmount.toString());
        if(printOrderIdVal != null)

        setPaymentSuccessFlag(true);
  
        else 
        alert("Failed to save payment data..");
            

      } else {
        //console.log(" PaymentSome error occured 143");
        //setMessage("Error occured...");
        setPaymentSuccessFlag(false);
        alert("Failed to save payment data..");
        //toastr.error('Error to save payments data!', {position : 'top-right', heading: 'Failed'});
      }

    }catch(error){

      //console.log("Inside SavePaymentsData : Ln 100 Error Code" + error);
      alert("Recived Service Error Failed to Save Payment in Database, " + error + "please try sfter some time..");
      //savePrintOrderData(props);
      //setPaymentSuccessFlag(true);
    }
    setSaveDB(true);
   
}
  const isDataValid = () => {

    console.log("Inside isDataValid Ln 161 personalInfo = " );
    console.dir(personalInfo);
    if(
       personalInfo.firstName.length == 0 || 
       personalInfo.lastName.length == 0 || 
       personalInfo.email.length == 0 || 
       personalInfo.address1.length == 0 ||
       personalInfo.country.length == 0 ||
       personalInfo.state.length == 0 ||
       personalInfo.zip.length == 0 ||
       personalInfo.mobileNo.length == 0)
     return false;
       else
     return true;
  }

  const EmptyCart = () => {
    return (
      <div className="container bg-primary text-white my-3 py-3">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">No item in Cart</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };

   const handleChange = (evt, param) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setPersonalInfo({
      ...personalInfo,
      [evt.target.name]: value
    });
  };

  const handleCheckChange = (event, param) => {
    
    if(isDataValid())
    {
      setChecked(event.target.checked);
    }
    else
       alert('Please enter valid data..');

  };

  const createEmailMessage =(Id, paymentId, price)=>{
    if(state.length > 0 )
    {
      const item = state[0];
      
      var base = "Congratulations.. Your Order is Successfully Registered..";
      var orderDetails= "Order Id: " +Id+"\n"+"Order Name: " + item.orderName +"\n" + "Order Type" + "Booking Date: " +item.bookingDatetime+"\n"
                     + "Booking Type: " +item.bookingType +"\n" +"Card Type: " +item.cardType+"\n"+"Card Size: "+item.cardSize+"\n"+
                     "Quaantity: "+item.quantity+"\n"+"Quality: "+item.quality+"\n" +"Total Payable Amount= "+ JSON.parse(localStorage.getItem('Price')) +"\n"+"Payment ID: "+ paymentId+"\n" +"Total Payment Done= "+price;

      //console.log("Final EmailContent Msg Ln 225 = " + base + "\n" +orderDetails);
      return (base + "\n" +orderDetails);

      }  

  }

   const savePrintOrderData = async (razorpayPaymentId, paidAmount) => {

    console.log("Ln 251 savePrintOrderData called.. ");
    const isFullPaymentDone = JSON.parse(localStorage.getItem('IsFullPaid'));
    var firstPay = 0;
    if(isFullPaymentDone === true){
      firstPay = JSON.parse(localStorage.getItem('AdvancePaymentFull'));

    }else{
      firstPay = JSON.parse(localStorage.getItem('AdvancePayment'));
    }
 
    const firstPayId = localStorage.getItem('Razorpay_Payment_Id');
    const price = JSON.parse(localStorage.getItem('Price'));
    console.log("**************************************************");
    console.log(" Ln 268 First Pay = ", firstPay);
    console.log("First Pay Id = ", firstPayId);
    console.log("Price = ", price);
    console.log("**************************************************");
    if(state.length > 0 )
   {
     const item = state[0];
     //console.dir("Item Dir Ln 236 = " + item);
    
    //e.preventDefault();
    //setShowProgres(true);
    try {
      let res  = await fetch("https://printsundar.online/loginAPI/addPrintOrder", {
        mode: 'cors',
        method: "POST",
        headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
        body: JSON.stringify({  
          mediaType: item.mediaType,
          taskOwner: "Barun",
          comments: "Hello Comments",
          taskStatus: "Pending",
          userName: personalInfo.firstName +" "+personalInfo.lastName,
          due: null,
          firstPayment: firstPay,
          secondPayment: 1524,
          firstPaymentTranId: firstPayId.toString(),
          secondPaymentTranId: "awes456",
          orderName: item.orderName,
          cardType: item.cardType,
          cardSize: item.cardSize,
          quality: item.quality,
          gsmValue: item.gsmValue,
          quantity: item.quantity,
          qrcode: item.qRCode,
          lamination: item.lamination,
          phoneNo: personalInfo.mobileNo,
          bookingType: "Print",
          registrationNo: "AK00125",
          bookingDatetime: new Date().toLocaleString(),
          users: [],
          spotUV: item.spotUV,
          texture: item.texture,
          transparent: item.transparent,
          specialColor: item.specialColor,
          smbossing: item.embossing,
          boolUploadFrontImage: item.boolUploadFrontImage,
          boolUploadBackImage: item.boolUploadBackImage,
          qrsingleBoth: item.qRSingleBoth,
          laminationSingleBoth: item.laminationSingleBoth,
          spotUVSingleBoth: item.spotUVSingleBoth,
          textureSingleBoth: item.textureSingleBoth,
          transparentSingleBoth: item.transparentSingleBoth,
          specialColorSingleBoth: item.specialColorSingleBoth,
          embossingSingleBoth: item.embossingSingleBoth,
          frontImagePath: null,
          backImagePath: null,
          qrcodeImagePath: null,
          price: price,
          taskType: "Print"          
        }),
      });
      //const resJson = await response.json();
      //const config = JSON.stringify(res);
      const resJson = await res.json();
      //console.log("Line 286  printOrderId = " + resJson.printOrderId);
      if (res.status === 200 || res.status === 201) {
          setprintOrderIdVal(resJson.printOrderId);
          alert("Print Order successfully saved into DB")
          //settotalCost(resJson.totalPrice);
          //console.log("PrintOrderId in resJson.printOrderId " + resJson.printOrderId);
          //console.log("PrintOrderId in printOrderIdVal " + printOrderIdVal);
          var emailMessage = createEmailMessage(resJson.printOrderId, razorpayPaymentId.toString(), resJson.totalPrice);
          //console.log("Print Order added successfully");
          setEmailMessage(emailMessage);
          console.log("Ln 298 Final Email msg  emailMessage= " + emailMessage);
          sendMailtoUser({emailMessage});
        
          // Code to check if front image and abck image files are there or not?
          var imageUploadUrl = "";
          const data = new FormData();
          if(item.frontImageFile != null && item.backImagePath != null)
          {
            imageUploadUrl =  "https://printsundar.online/loginAPI/upload2File";
            data.append('printId', resJson.printOrderId);
            data.append('file1', item.frontImageFile);
            data.append('file2', item.backImageFile);
           
            
          }
          if (item.frontImageFile != null)
          {
            imageUploadUrl =  "https://printsundar.online/loginAPI/upload1File";
            data.append('printId', resJson.printOrderId);
            data.append('file1', item.frontImageFile);
            
          }
          //console.dir(" Ln 371 Data Details..." + data);
          //console.dir(" Ln 372 Servie url = " + imageUploadUrl);        
          let res = await fetch(
            imageUploadUrl,
            {
              method:'POST',
              mode:'no-cors',
              body:data,
              //headers: {
                //'content-type': item.frontImageFile.type,
                //'content-length': `${item.frontImageFile.size}`, // 👈 Headers need to be a string
             // }
              
            }
          ).then(async response=>{
              const data = await response.json();
              // check for error response
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          else{

             console.log("Succesfully saved IMAGES into attachments table");
          }


          })
          
            let responseJson = await res.json();
          
        
      } else {
        console.log("Some error occured");
        //setMessage("Error occured...");
        //toastr.error('Error to add task!', {position : 'top-right', heading: 'Failed'});
      }
      //Clear cart
          
    } catch (err) {
      console.log(err);
    }
  }
  };
  const loadScript=(src)=>{
    console.log("loadScript called ln 346..")
    return new Promise((resolve)=>{
        
      const script = document.createElement('script')
      script.src = src

      script.onload=()=>{

        resolve(true)
      }

      script.onerror=()=>{

        resolve(false)
      }

      document.body.appendChild(script)
    })

  }

  const handleSubmitOrder = async(e)=>{
    //const paymentId = useRef(null);
    //const paymentMethod = useRef(null);
    setresRazorPaySDK("True");
    // const finalPrice = JSON.parse(localStorage.getItem('Price'));
    const isFullPaymentDone = JSON.parse(localStorage.getItem('IsFullPaid'));
    var finalPrice = 0;
    if(isFullPaymentDone === true){
      finalPrice = JSON.parse(localStorage.getItem('AdvancePaymentFull'));

    }else{
      finalPrice = JSON.parse(localStorage.getItem('AdvancePayment'));
    }
     
    //alert("Razorpay finalPrice = " + finalPrice);
      
    /*const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );*/
  if (!resRazorPaySDK) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
    }
      const result  = await axios.post('https://nodejsserver.online/razorpay',{
      amount: finalPrice.toString(),
      currency: "INR"
     }).then(response=>{

      if (!response ) {
        alert("Server error. Are you online?");
        return;
    }
      //alert("Razorpay SDK returned response " + response.data);
      console.log("Line 471 CheckoutProduct response.data.order_id = " + response.data.order_id);
      const { amount, id: order_id, currency } = response.data;
      setresRazorPaySDK("");
      var options = {
      key_id:process.env.RAZORPAY_KEY_ID,
      amount:finalPrice.toString(), 
      currency:"INR",
      name: "Sundar",
      description: "Razorpay Transaction",
      order_id: order_id,
      handler: function (response){
          alert(response.razorpay_payment_id);
          alert(response.razorpay_order_id);
          alert(response.razorpay_signature)
          localStorage.setItem("Razorpay_Payment_Id", response.razorpay_payment_id);
          const data = {
            orderCreationId: response.razorpay_order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          //If paymentfails then 
          //https://nodejsrazorpay.onrender.com/verification
          axios.post("https://nodejsserver.online/verification", data).then((response)=>{

            //console.log("Line 434 Payment Result = " + response.data)
            alert("Razorpay status " + response.status);
            console.log("Line 434 Payment Result = " + response.status);
            if(response.status === 200)
             {
              alert("Congratulations..Payment Successful..Return Code = " + response.status);
              try{
                savePaymentsData(data.razorpayPaymentId, options.amount );
                navigate("/Congratulations", { state: data });
             
              }catch(error){
             
               }

             }else{

              alert("Sorry..Payment Failed..");
              try{
                navigate("/Failure");
             
              }catch(error){
             
               }
             }
           

          }).catch((error) => {
            

          })
                 
         
      },
      prefill: {
        name: personalInfo.firstName +" "+personalInfo.lastName,
        email: personalInfo.email,
        phone_number: personalInfo.mobileNo,
      },
       
      theme: {
          "color": "#3399cc"
      }
  };

  var rzp1 = new window.Razorpay(options);
  rzp1.open();
     
     });
        
  }

  const handleSubmitOrderPhonePe = async(e)=>{
    //const paymentId = useRef(null);
    //const paymentMethod = useRef(null);
    setresRazorPaySDK("True");
    // const finalPrice = JSON.parse(localStorage.getItem('Price'));
    const isFullPaymentDone = JSON.parse(localStorage.getItem('IsFullPaid'));
    var finalPrice = 0;
    if(isFullPaymentDone === true){
      finalPrice = JSON.parse(localStorage.getItem('AdvancePaymentFull'));

    }else{
      finalPrice = JSON.parse(localStorage.getItem('AdvancePayment'));
    }
     
    //alert("Razorpay finalPrice = " + finalPrice);
      
    /*const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
  );*/
  if (!resRazorPaySDK) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
    }
      const result  = await axios.post(serverCreate,{
      amount: finalPrice.toString(),
      currency: "INR"
     }).then(response=>{

      if (!response ) {
        alert("Server error. Are you online?");
        return;
    }
      alert("Razorpay SDK returned response " + response.data);
      console.log("Line 471 CheckoutProduct response.data.order_id = " + response.data.order_id);
      const { amount, id: order_id, currency } = response.data;
      setresRazorPaySDK("");
      var options = {
      key_id:process.env.RAZORPAY_KEY_ID,
      amount:finalPrice.toString(), 
      currency:"INR",
      name: "Sundar",
      description: "Razorpay Transaction",
      order_id: order_id,
      handler: function (response){
          alert(response.razorpay_payment_id);
          alert(response.razorpay_order_id);
          alert(response.razorpay_signature)
          localStorage.setItem("Razorpay_Payment_Id", response.razorpay_payment_id);
          const data = {
            orderCreationId: response.razorpay_order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          //If paymentfails then 
          axios.post("https://nodejsserver.online/verification", data).then((response)=>{

            //console.log("Line 434 Payment Result = " + response.data)
            alert("Razorpay status " + response.status);
            console.log("Line 434 Payment Result = " + response.status);
            if(response.status === 200)
             {
              alert("Congratulations..Payment Successful..Return Code = " + response.status);
              try{
                savePaymentsData(data.razorpayPaymentId, options.amount );
                navigate("/Congratulations", { state: data });
             
              }catch(error){
             
               }

             }else{

              alert("Sorry..Payment Failed..");
              try{
                navigate("/Failure");
             
              }catch(error){
             
               }
             }
           

          }).catch((error) => {
            

          })
                 
         
      },
      prefill: {
        name: personalInfo.firstName +" "+personalInfo.lastName,
        email: personalInfo.email,
        phone_number: personalInfo.mobileNo,
      },
       
      theme: {
          "color": "#3399cc"
      }
  };

  var rzp1 = new window.Razorpay(options);
  rzp1.open();
     
     });
        
  }

  return (
    <>
      <Navbar />
     
      <div className="container bg-primary text-white my-3 py-3">
        <h1 className="text-center">Checkout Product</h1>
        <h4 className="text-center">Enter Delivery Address Details</h4>
        <hr />
        {state.length > 0}
        {
          <>
            <div className="card-body">
              <form>
                <div className="row g-3">
                  <div className="col-sm-6 my-1">
                    <label for="firstName" className="form-label text-white">
                      First name
                    </label>
                    <input
                      type="text"
                      key="user_fname_key"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={personalInfo.firstName}
                      onChange={handleChange}

                    />
                  </div>
                  <div className="col-sm-6 my-1">
                    <label for="lastName" className="form-label text-white">
                      Last name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder=""
                      name="lastName"
                      onChange={handleChange}
                      value={personalInfo.lastName}
                    />
                  </div>

                  <div className="col-6 my-1">
                    <label for="email" className="form-label text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      value={personalInfo.email}
                      placeholder="you@example.com"

                    />
                  </div>
                  <div className="col-6 my-1">
                    <label for="MobileNo" className="form-label text-white">
                      Mobile No
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobileNo"
                      onChange={handleChange}
                      value={personalInfo.mobileNo}
                      placeholder="Mobile Number"

                    />
                  </div>

                  <div className="col-12 my-1">
                    <label for="address" className="form-label text-white">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="address1"
                      onChange={handleChange}
                      value={personalInfo.address1}
                      placeholder="Enter address here.."

                    />
                  </div>
                  <div className="col-6 my-1">
                    <label for="address2" className="form-label text-white">
                      Address 2{" "}
                      <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="address2"
                      onChange={handleChange}
                      value={personalInfo.address2}
                      placeholder="Apartment or suite"
                    />
                  </div>
                  <div className="col-6 my-1">
                    <label for="address2" className="form-label text-white">
                      Nearest Landmark{" "}
                      <span className="text-muted">(Optional)</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="landmark"
                      onChange={handleChange}
                      value={personalInfo.landmark}
                      placeholder="Nearest landmark"
                    />
                  </div>
                  <div className="col-md-5 my-1">
                    <label for="country" className="form-label text-white">
                      Country
                    </label>
                    <br />
                    <select className="form-select" id="country" required>
                      <option>India</option>
                      <option>Sri Lanka</option>
                      <option>Bangaladesh</option>
                    </select>
                  </div>
                  <div className="col-md-4 my-1">
                    <label for="state" className="form-label text-white">
                      State
                    </label>
                    <br />
                    <select className="form-select" id="state" required>
                      <option>West Bengal</option>
                      <option>Delhi</option>
                      <option>Bihar</option>
                      <option>Hariyana</option>
                      <option>Maharastra</option>
                      <option>Tamilnadu</option>
                      <option>Karnataka</option>
                    </select>
                  </div>
                  <div className="col-md-3 my-1">
                    <label for="zip" className="form-label text-white">
                      Zip
                    </label>
                    <input
                      type="number"
                      onChange={handleChange}
                      className="form-control"
                      name="zip"
                      id="zip"
                      value={personalInfo.zip}
                      placeholder=""
                    />
                   </div>
                  <br />
                </div>
              </form>
              <div class="container">
              <div class="row">
                <div className="col-md-9 offset-md-3">
                <input 
                class="form-check-input" 
                type="checkbox" 
                name="checked"
                checked={checked}
                onChange={handleCheckChange}/>
                 <label class="form-check-label" for="flexCheckChecked" >
                 I agree to terms and conditions of Sundar
       </label>
                    
       </div>
      </div>
   
            </div>
            <div class="col-md-9 mt-2 offset-md-3">
               {checked &&
                 <Button
                 className="wx-250 btn btn-warning text-white"
                 onClick = {()=>handleSubmitOrder()}
               >
                 Pay with Razorpay
               </Button>   
                                          
              }
                         
            </div>
           
           
            </div>
           
          </>
        }
      </div>
      <Footer />
    </>
  );
};

export default CheckoutProduct;
