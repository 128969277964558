import React from 'react'
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { delCart } from "../redux/action";


const Congratulations = () => {

  const price = JSON.parse(localStorage.getItem('Price'));
  const [product, setProduct] = React.useState({
    orderName:"",
    booking_type:"",
    cardType: "",
    cardSize: "",
    mediaType: "",
    quality: "",
    gsmValue: "",
    quantity: "",
    qRCode: false,
    qRSingleBoth: "Back",
    lamination: "",
    laminationSingleBoth: "",
    spotUV: "",
    spotUVSingleBoth: "",
    texture: "",
    textureSingleBoth: "",
    transparent: "" ,
    transparentSingleBoth: "",
    specialColor: "",
    specialColorSingleBoth: "",
    embossing: "",
    embossingSingleBoth: "",
    boolUploadFrontImage:"",
    frontImagePath:"",
    boolUploadBackImage:"",
    backImagePath:"",
    QRCodeImagePath:"",
    price: "",
    frontImageFile:"",
    backImageFile:"",
    QRImageFile:"",
    
  })

  const state = useSelector((state) => state.handleCart);

  //setProduct(state);
  //console.dir("line 48 State Returned =="+ state[0]);

  return (
    <>
       <Navbar />
      <div className="container bg-primary text-white my-2 py-2">
        <h1 className="text-center"></h1>
        <hr />
        <section className="h-200 gradient-custom">
          <div id="Print" className="container bg-primary text-black py-2">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Order Submitted Successfully..</h4>
                    </div>
                  <div className="card-body">
                    <p> </p>
                  </div>
                </div>
              </div>
             </div>
          </div>
        </section>         
       </div>
       <div className="min-h-screen bg-gray-100">
       <section className="h-100 gradient-custom">
          <div id="Print" className="container bg-primary text-black py-2">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Order Summary</h4>
                    </div>
                  <div className="card-body">
                    {state.map((item) => {

                     return (
                        <div key={item.id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-9 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src= {item.frontImagePath}   // //item.frontImagePath
                                  // className="w-100"
                                  alt="image"
                                  width={400}
                                  height={300}
                                />
                              </div>
                            </div>

                            <div className="col-lg-9 col-md-12 py-3">
                              <p>
                                <strong>Order Name : {item.orderName}</strong>
                                </p>
                                <p>
                                <strong>Card Type : {item.cardType}</strong>
                                </p>
                                <p>
                                <strong>Card Size : {item.cardSize}</strong>
                                </p>
                                <p>
                                <strong>Media Type : {item.mediaType}</strong>
                                </p>
                                <p>
                                <strong>Quality : {item.quality}</strong>
                                </p>
                                <p>
                                <strong>Quantity : {item.quantity}</strong>
                                </p>
                                <p>
                                <strong> Front Image : {item.frontImagePath}</strong>
                                </p>
                                <p>
                                <strong>Back Image : {item.backImagePath}</strong>
                                </p>
                                <p>
                                <strong>QR Image : {item.QRCodeImagePath}</strong>
                                </p>
                                <p>
                                <strong>qRCode : {item.qRCode}</strong>
                                </p>
                                <p>
                                <strong>GSM Value : {item.gsmValue}</strong>
                                </p>
                                                         
                            </div>

                          </div>
                          <hr className="my-4" />
                         </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card mb-4">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Pricing Details</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                       Total Price <span>Rs {Math.round(price)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        Delivery Charges
                        <span>Rs {Math.round(price * 0)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                          <strong>Net Amount Paid</strong>
                        </div>
                        <span>
                          <strong>Rs {Math.round(price + price * 0)}</strong>
                        </span>
                      </li>
                    </ul>
                                      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
      <Footer />
    </>
  )
}

export default Congratulations