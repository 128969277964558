import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
//import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';

const Checkout = () => {
  const state = useSelector((state) => state.handleCart);
  const [firstName, setFirstName] = useState();
  const [cvvValue, setCvvValue] = useState();
  const [timer, setTimer] = useState(null)

  const [personalInfo, setPersonalInfo] = React.useState({
    firstName:"",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    zip: "",
    cardHolderName: "",
    cardNumber: "",
    expiration: "",
    cVV: ""
   
  })
  
  const EmptyCart = () => {
    return (
      <div className="container bg-primary text-white my-3 py-3">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">No item in Cart</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const handleFirstName=(param)=>{

    setFirstName(param.target.value);
    console.log("Entered handleFirstName= " + param.target.value);
       
  }


  const handleCvvValue=(param)=>{

    setCvvValue(param.target.value);
   // console.log("Entered setCvvValue= " + param.target.value);
       
  }
  const handleChange = (evt, param) => {
    const value = 
    evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setPersonalInfo({
      ...personalInfo,
      [evt.target.name]: value
    });
  };

  const handleSubmit = async (e) => {

    console.log("Ln 65 handleSubmit called.. ");
    const item = [];
/*
    if(personalInfo.firstName.length == 0 )
     alert("Enter First Name")
     if(personalInfo.lastName.length == 0 )
     alert("Enter Last Name")
     if(personalInfo.email.length == 0 )
     alert("Enter Email")
     if(personalInfo.address1.length == 0 )
     alert("Enter Address")

     if(personalInfo.country.length == 0 )
     alert("Enter Country")
     if(personalInfo.state.length == 0 )
     alert("Enter State")

     if(personalInfo.zip.length == 0 )
     alert("Enter Zip")

     if(personalInfo.cardHolderName.length == 0 )
     alert("Enter Card Holder Name")

     if(personalInfo.cardNumber.length == 0 )
     alert("Enter Card Number")

     if(personalInfo.expiration.length == 0 )
     alert("Enter Expiration")

     if(personalInfo.cVV.length == 0 )
     alert("Enter CVV")
 */
     try {
      let res = await fetch("http://153.92.4.115:8080/loginAPI/addPrintOrder", {
        mode: 'cors',
        method: "POST",
        headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
        body: JSON.stringify({  
          print_order_id:(Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          qrcode_image_path:item.item.QRCodeImagePath,
          back_image_path :item.backImagePath,
          booking_datetime :new Date(),
          booking_type: "Print",
          bool_upload_back_image :item.boolUploadBackImage, 
          bool_upload_front_image : item.boolUploadFrontImage,
          card_size :item.cardSize, 
          card_type :item.cardType, 
          comments :"Comments",
          embossing :item.embossing,
          embossing_single_both :item. embossingSingleBoth,
          front_image_path :item. frontImagePath,
          gsm_value :item.gsmValue, 
          lamination :item. lamination,
          lamination_single_both: item. laminationSingleBoth,
          media_type : item.mediaType,
          order_name :item.orderName,
          phone_no :"1234567890", 
          price :item.price,
          qrcode : item.qRCode,
          qrsingle_both : item.qRSingleBoth,
          quality: item.quality,
          quantity : item.quantity,
          registration_no : "123654782", 
          special_color :item.specialColor,
          special_color_single_both : item.specialColorSingleBoth,
          spotuv: item.spotUV,
          spotuvsingle_both : item.spotUVSingleBoth,
          task_owner : "Task Owner",
          task_status : "Pending",
          task_type :"VC Print",
          texture :item.texture, 
          texture_single_both : item.textureSingleBoth,
          transparent : item.transparent,
          transparent_single_both : item.transparentSingleBoth,
          user_name :"Barun" 
          
        }),
      });
      let resJson = await res.json();
      //console.log("Ln 132 return from DB insert addprintOrder" + res.status);
      //setShowProgres(false);
      if (res.status === 200) {
         //setTaskData([]);
          console.log("Task added successfully");
          //setMessage("User added successfully");
          //toastr.success('New User added successfully!', {position : 'top-right', heading: 'Done'});    
          //reSetFormData();
          //hideModal();
       
      } else {
        console.log("Some error occured");
        //setMessage("Error occured...");
        //toastr.error('Error to add User!', {position : 'top-right', heading: 'Failed'});
      }
    } catch (err) {
      console.log(err);
    }

  };

  const ShowCheckout = () => {
    let subtotal = 0;
    let shipping = 0.0;
    let totalItems = 0;
    state.map((item) => {
      return (subtotal += item.price * item.qty);
    });

    state.map((item) => {
      return (totalItems += item.qty);
    });

     return (
      <>
        <div className="container  my-3 py-5">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0 text-black">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})<span>Rs {Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Shipping
                      <span>Rs {shipping}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>
                      </div>
                      <span>
                        <strong>Rs {Math.round(subtotal + shipping)}</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0 text-black">Billing address</h4>
                </div>
                 </div>
            </div>
          </div>
        </div>
      </>
    );
  };



  return (
    <>
      <Navbar />
      <div className="container bg-primary text-white my-3 py-3">
        <h1 className="text-center">Checkout</h1>
        <hr />
        {state.length > 0}
        {
          <>
            
             <div className="card-body">
                  <form>
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label for="firstName" className="form-label text-white">
                          First name
                        </label>
                        <input
                          type="text"
                          key="user_fname_key" 
                          className="form-control"
                          id="firstName"
                          name="firstName" 
                          placeholder="First Name"
                          value={firstName}
                          onChange={handleFirstName}
                          required
                          />
                           <div className="invalid-feedback"
                        name="firstName" 
                        onChange={handleChange} 
                        value={personalInfo.firstName}
                        >
                          First name is required.
                        </div>
                       </div>

                      <div className="col-sm-6 my-1">
                        <label for="lastName" className="form-label text-white">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="Last Name"
                          name="lastName" 
                          onChange={handleChange} 
                          value={personalInfo.lastName}
                          required
                        />
                       
                      </div>

                      <div className="col-12 my-1">
                        <label for="email" className="form-label text-white">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email" 
                          onChange={handleChange} 
                          value={personalInfo.email}
                          placeholder="you@example.com"
                          required
                        />
                       
                      </div>

                      <div className="col-12 my-1">
                        <label for="address" className="form-label text-white">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address1" 
                          onChange={handleChange} 
                          value={personalInfo.address1}
                          placeholder="1234 Main St"
                          required
                        />
                     
                      </div>

                      <div className="col-12">
                        <label for="address2" className="form-label text-white">
                          Address 2{" "}
                          <span className="text-muted">(Optional)</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="address2" 
                          onChange={handleChange} 
                          value={personalInfo.address2}
                          placeholder="Apartment or suite"
                        />
                      </div>

                      <div className="col-md-5 my-1">
                        <label for="country" className="form-label text-white">
                          Country
                        </label>
                        <br />
                        <select className="form-select" id="country" required>
                        <option>India</option>
                        <option>Sri Lanka</option>
                        <option>Bangaladesh</option>
                       </select>
                      </div>
                      <div className="col-md-4 my-1">
                        <label for="state" className="form-label text-white">
                          State
                        </label>
                        <br />
                        <select className="form-select" id="state" required>
                          <option>West Bengal</option>
                          <option>Delhi</option>
                          <option>Bihar</option>
                          <option>Hariyana</option>
                          <option>Maharastra</option>
                          <option>Tamilnadu</option>
                          <option>Karnataka</option>
                        </select>
                       </div>

                      <div className="col-md-3 my-1">
                        <label for="zip" className="form-label text-white">
                          Zip
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="zip"
                          placeholder="Zip Code"
                          required
                        />
                        <div className="invalid-feedback"
                        name="zip" 
                        onChange={handleChange} 
                        value={personalInfo.zip}
                        >
                          Zip code required.
                        </div>
                      </div>
                    </div>

                    <hr className="my-4" />

                    <h4 className="mb-3">Payment</h4>

                    <div className="row gy-3">
                      <div className="col-md-6">
                        <label for="cc-name" className="form-label text-white"
                         name="cardHolderName" 
                         onChange={handleChange} 
                         value={personalInfo.cardHolderName}
                        >
                          Name on card
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-name"
                          placeholder="Name"
                          required
                        />
                        <small className="text-muted text-white" >
                          Full name as displayed on card
                        </small>
                       </div>

                      <div className="col-md-6">
                        <label for="cc-number" className="form-label text-white"
                         name="cardNumber" 
                         onChange={handleChange} 
                         value={personalInfo.cardNumber}
                        >
                          Credit card number
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="cc-number"
                          placeholder="Credit card number"
                          required
                        />
                       </div>

                      <div className="col-md-3">
                        <label for="cc-expiration" className="form-label text-white"
                          >
                          Expiration
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="cc-expiration"
                          name="expiration" 
                          onChange={handleChange} 
                          value={personalInfo.expiration
                          }
                          required
                        />
                       
                      </div>

                      <div className="col-md-3">
                        <label for="cc-cvv" className="form-label text-white">
                          CVV
                        </label>
                        <input
                          type="number"
                          key="user_CVV"
                          className="form-control"
                          id="cvv"
                          name="cvv"
                          value={cvvValue}
                          onChange={handleCvvValue}
                          required
                        />
                       
                      </div>
                    </div>
                    
                    <hr className="my-4" />
                    <div class="col-md-10 my-1"> 
                    <button
                      className="wx-75 btn btn-warning text-white"
                      type="submit"
                      onClick = {handleSubmit}
                    >
                      Continue to checkout
                    </button>
                        </div>
                    
                  </form>
                </div>
              
          </>
         
        }
       </div>
      <Footer />
    </>
  );
};

export default Checkout;
