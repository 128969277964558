
export const PriceList_VC_RoundedRectangle = [
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Single_Lamination',
        price: 0.10
    },
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Both_Lamination',
        price: 0.19
    },
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Single_SpotUV',
        price: 1.25
    },
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Both_SpotUV',
        price: 2.25
    },
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Special_Color',
        price: 1.5
    },
    {
        name: 'Rounded Rectangle_8.9 x 5.1_Embossing',
        price: 1.5
    },
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_160-180_Single',
    price: 2.1
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_200-220_Single',
    price: 1.85
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_250_Single',
    price: 2.25
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_300_Single',
    price: 2.3
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_350_Single',
    price: 2.5
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_160-180_Double',
    price: 4.1
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_200-220_Double',
    price: 4.2
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_250_Double',
    price: 4.4
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_300_Double',
    price: 4.5
}, 
{
    name: 'Rounded Rectangle_8.9 x 5.1_GSM_350_Double',
    price: 4.7
}, 


{
    name: 'Rounded Rectangle',
    price: 0.51
}, 

{
    name: 'Square',
    price: 0.55
},
{
    name: '8.9 x 5.1',
    price: 0.8
},
{
    name: '8.9 x 5.5',
    price: 0.5
},
{
    name: '6.3 x 6.3',
    price: 0.99
},
{
    name: 'Paper',
    price: 0.06
},
{
    name: 'Board',
    price: 1.52
},
{
    name: 'PVC',
    price: 2.05
},
{
    name: 'Non-Tearable Sheet',
    price: 2.85
},
{
    name: 'Velvet',
    price: 2.75
},
{
    name: 'Transparent',
    price: 1.95
},
{
    name: 'Glossy',
    price: 2.85
},
{
    name: 'Matte',
    price: 2.45
},
{
    name: 'QR code',
    price: 0.55
},
{
    name: 'Lamination',
    price: 0.55
},
{
    name: 'Spot UV',
    price: 0.55
},
{
    name: 'QR code',
    price: 0.55
},
{
    name: '160-180',
    price: 0.59
},
{
    name: '200-220',
    price: 0.62
},
{
    name: '250',
    price: 0.64
},
{
    name: '350',
    price: 0.69
},

];



