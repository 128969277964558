import React from 'react';
import ReactDOM from 'react-dom/client';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

import Checkout from './pages/Checkout';

import { Home, Product, Products, AboutPage, ContactPage, Cart, Login, LoginButton, Register, CheckoutProduct, PageNotFound, Congratulations,Failure, BusinessSupport, Vacancy, TermsAndCondition, PrivacyPolicy, RefundPolicy, CancellationPolicy, ShippingPolicy } from "./pages"

import VisitingCards from './components/VisitingCards';
import SubmitOrder from './pages/SubmitOrder';

import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
  domain="dev-8npywf3xhsejob3t.us.auth0.com"
  //clientId="a8jXRRHUwo7DHW8UfyjipQvySsNT9udC" // SundarPrint Production
  clientId="qfXBUzWjcXJalQAYJnh75JYLPQoAVaoN" // React_E-Commerce Main Test
  authorizationParams={{
    redirect_uri: window.location.origin
  }}
 >
  <HashRouter>
     <Provider store={store}>
       <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/BusinessSupport" element={<BusinessSupport />} />
        <Route path="/Vacancy" element={<Vacancy />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/LoginButton" element={<LoginButton />} />
        <Route path="/register" element={<Register />} />
        <Route path="/CheckoutProduct" element={<CheckoutProduct />} />
        <Route path="/Checkout" element={<Checkout/>} />
        <Route path="/VisitingCards" element={<VisitingCards />} />
        <Route path="/SubmitOrder" element={<SubmitOrder />} />
        <Route path="/Congratulations" element={<Congratulations />} />
        <Route path="/Failure" element={<Failure />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/RefundPolicy" element={<RefundPolicy />} />
        <Route path="/CancellationPolicy" element={<CancellationPolicy />} />
        <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/product/*" element={<PageNotFound />} />
      </Routes>
    </Provider>
  </HashRouter>
  </Auth0Provider>
);