import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";

import StandardImage from '../images/Standard.PNG';
import NonTearable from '../images/NonTearable.png';
import InvitationCard from '../images/QRCoadPremiuglossy.png';
import SpotUVspecialVisitingCard from '../images/SpotUVspecialVisitingCard.png';

import Transparentvisitingcard from '../images/TransparentVC.png';
//import CornerVisitingCard from '../images/CornerVisitingCard.png';
import Spot_UV from '../images/Spot_UV.png';
import MatteVC from '../images/MatteVC.png';
import SpecialcolourVC from '../images/SpecialcolourVC.png';

import Magneticvisitingcard from '../images/Magnetic.png';
import ThreeDEffectvisitingcard from '../images/3DEffect.png';
import UniqueShapevisitingcard from '../images/UniqueShape.png';
import Photographicvisitingcard from '../images/photographic.png';



import { Link, useNavigate } from "react-router-dom";


import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Products = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  const state = useSelector((state) => state.handleCart);

  const addProduct = (product) => {
    if(state.length == 0)
       dispatch(addCart(product));
    else
     {
      dispatch(delCart(product));
      dispatch(addCart(product));

     }
  }

  useEffect(() => {
    /*const getProducts = async () => {
      setLoading(true);
      const response = await fetch("https://fakestoreapi.com/products/");
      if (componentMounted) {
        setData(await response.clone().json());
        setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();*/
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  }

  const ShowProducts_1 = () => {
    return (
      <>
         <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={StandardImage} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Standard Visiting Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={NonTearable} alt="" height={150} />
              <div className="card-body">
                <h5 className="card-title text-center">Non-Tearable Visiting Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={InvitationCard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">QR Code Visiting Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={SpotUVspecialVisitingCard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Velvet Touch Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
        </div>     
     
      </>
    );
  };
  const ShowProducts_2 = () => {
    return (
      <>
         <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={Transparentvisitingcard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Transparent Visiting Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={Spot_UV} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Spot UV Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={MatteVC} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Matte Visiting Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={SpecialcolourVC} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Special Colored Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  const ShowProducts_3 = () => {
    return (
      <>
         <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={Magneticvisitingcard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Magnetic Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={ThreeDEffectvisitingcard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">3D Effect Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={UniqueShapevisitingcard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Unique Shape Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-170">
              <img className="card-img-top img-fluid" src={Photographicvisitingcard} alt="" height={170} />
              <div className="card-body">
                <h5 className="card-title text-center">Photo Graphic Card</h5>
              </div>
              <Link to={"/VisitingCards/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              <button className="btn btn-dark m-1" onClick={() => addProduct()}>
                    Add to Cart
                  </button>
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Popular Products</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts_1 />}
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts_2 />}
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts_3 />}
        </div>
       </div>
    </>
  );
};

export default Products;
