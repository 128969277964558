import React from 'react'
import { Footer, Navbar } from "../components";

import {img} from "react-bootstrap/Image";

import AboutUsImage from '../images/about us.png';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="row">
    <div className="col-12 ">
    
     <img className="card-img-top img-fluid" src={AboutUsImage} alt="" height={160} />
      </div> 
      </div>
      <div className="container my-3 py-3">
      
      <h2>Greetings User</h2>
            <h3>Privacy Policy</h3>
            <div class="terms-text">
            <p className="lead text-start">Our Privacy Policy confidently outlines the information we collect, how we use, share and protect it, and the 
ways in which you can exercise your rights.
Our Privacy Policy:1. Applies to all our services.2. We collect Personal Information directly or through our 
website.3. We use your Personal Information to provide and improve our services.4. We collect your Personal 
Information with your consent or when it is necessary to provide our services.5. We do not knowingly collect 
Personal Information from children under 13.6. We do not disclose or share your Personal Information with any 
third parties except as required to provide our services.7. We may transfer your Personal Information to servers 
located in other countries.8. You have the right to access, correct, and delete your Personal Information.9. You 
can manage Cookies through your browser settings.10. Contact us through our website if you have any 
questions or concerns.11. We may update our privacy policy from time to time.
Supplemental Privacy Pages i. Special Notice for California Residents ii. Special Notice for Nevada Residents
This Privacy Policy applies to all users of our Sites and Applications, including both registered and unregistered 
users, designer contributors, as well as our customers or potential customers. It covers all Sundar Services that 
link to this Privacy Policy. We collect personal information related to an identifiable natural person (referred to as 
"Personal Information") to provide you with our Services. This Privacy Policy governs how we handle the 
information we collect from you while using our Services.
Our Privacy Policy only applies to Personal Information collected on our Sundar Sites and Apps. We are not 
responsible for the privacy practices or Personal Information collected by third-party websites and Apps 
accessible through links or ads on our sites.
</p>
<p className="lead text-start">
Please read our Privacy Policy and supplemental pages before using Sundar Services to understand how your 
Personal Information is handled. By using our Sundar Sites, Apps, and Services, you agree to this Privacy 
Policy. For general inquiries about this Policy, contact our privacy team at dataprotection@sundar.com.
SUNDAR is a global e-commerce company that provides design and marketing services to small businesses 
through its signature services, including Sundar Print, Sundar Create, and 99designs by Sundar. It is operated 
by Cimpress Schweiz GmbH and other Cimpress plc subsidiaries.
When using Sundar Services and creating an account, Cimpress Schweiz GmbH and/or another Cimpress plc 
subsidiary control your Personal Information. The list of relevant data controllers is provided below. They work 
together to provide print, digital and design services worldwide and may act as joint data controllers for your 
SUNDAR account. However, they cannot act as legal representatives for each other.
In relation to your Sundar account: Cimpress Schweiz GmbH Talacker 41 8001 Zürich Switzerland
In relation to Sundar Print Services:
Sundar collects Personal Information from various sources for its print, digital, and design services. Information 
may be provided by you when you register, fill out a form, purchase a product or service, or communicate with 
us. We may also obtain information from third parties and social media websites. If you choose not to share 
certain Personal Information, we may not be able to provide some of our services.
We automatically collect device and location data when you use our websites and apps. This includes your IP
address, computer software and hardware information, and location data. We use cookies and similar 
technologies to collect this data. For more information, see our Cookies section.
We automatically collect and store information about your activities on our Sites and Apps, such as the pages 
you visit, time and date of your visit, and how you use our Services (including search history, clickstream data, 
access logs, and other usage data).
</p>
<p className="lead text-start">
Privacy Policy
We collect session replay recordings, purchase and transaction history, and chatbot usage data from you. If you 
have a registered account, we link this data to other personal information we have collected from you. When you 
register for an account, we collect your name, email, and password.
We collect your shipping, billing, payment, tax, and identity verification information when you place an order or 
request a service. You may also have the option to store payment information for future purchases.
Our Services allow you to share personalized content with us and other users, which may include personal 
information. Image information like time, place and tags may also be included.
We collect demographic information about you, such as gender, country, and language. For designers, we store 
profile, content, and preferences. Some of this is public.
We collect feedback, help requests, and communication preferences from you when you contact our customer 
service teams or connect with us through social networks. Additionally, we collect your direct marketing 
preferences and preferences related to our third-party associates.
Occasionally, we may offer you the chance to participate in surveys, contests, or sweepstakes. If you decide to 
take part, we will ask for certain personal information. This information may be made public.
We may ask you to write a review on our Sites and Apps. Your review and displayed name will be public. Only 
include information you're comfortable sharing publicly.
Your uploaded content may contain personal information of others. If you share it with someone, we'll use their 
personal information to fulfill your request as per our Privacy Policy. Ensure you have their consent and they 
understand how Vista uses their personal information before sharing it.
We may receive your personal information from publicly available sources, third parties, business partners, and 
Cimpress plc subsidiaries. This information will be combined with the information you provide us in accordance 
with our Privacy Policy.
</p>
<p className="lead text-start">
We obtain Personal Information from third-party sources such as postal service providers, security providers, 
social media platforms, lead enhancement companies, and advertising partners to improve our service offering 
and serve you more relevant advertising.
We collect Personal Information of visitors and users of our User’s websites or services ("End Users 
Information") for you. Vista serves as a "Processor" and not as a "Controller" of such information, as defined in 
GDPR. You are responsible for complying with all laws and regulations regarding the collection and control of 
such information. The processing and transfer of the End Users Information will follow the Data Processing 
Agreement (DPA).
</p>
<p className="lead text-start">
We collect your Personal Information to create and maintain your account, provide you with our services, and 
offer customer support. This includes managing and delivering your orders, allowing you to access and use our 
Services, and sharing relevant data with our Customer Care team for quality assurance. We may monitor or 
record calls with our Customer Care team for training purposes and delete the recordings automatically after a 
limited time, unless it is necessary to retain them for legal or fraud investigation purposes.
We analyze your use of our services to improve them and develop new features. We enable communication and 
collaboration with other users. We send you notifications about your account and updates related to our 
services. We appreciate your feedback about our services and customer care.
We use your information for marketing purposes, including sending promotions and offers for our products, 
services, and those offered by our affiliated companies. We may also share news about upcoming products and 
personalize our advertisements based on your interests. This will not have any significant legal effect on you.
We personalize our services and communications to provide you with tailored experiences. This includes market 
research and marketing activities, content customization, and grouping users based on usage and 
demographics.
We prioritize security and aim to prevent fraud and unauthorized access. We improve network security and data 
protection by profiling your interactions with our services and content you submit. We also obtain data from third 
parties to lock stolen or fraudulent accounts.
We may share your Personal Information to comply with legal obligations, including responding to subpoenas, 
court orders, and lawful requests by regulators and law enforcement. This includes managing legal claims and 
disputes, investigating regulatory compliance, enforcing our Services' terms and conditions, complying with 
government requirements, and protecting Vista, our employees, customers, and others.
We are required by law to disclose the legal grounds on which we collect your Personal Information. As per this 
requirement, we collect and process your Personal Information based on the following legal grounds:
We collect and process your Personal Information to fulfill contracts or take steps to enter into a contract with 
you, such as fulfilling your order or providing customer service and technical support.
When required by law or regulations, we may share information in response to law enforcement requests.
We use your personal information for our legitimate business interests, which includes maintaining and 
improving our services, developing new features, corresponding with you, and protecting our legal rights and 
those of third parties. We may also analyze your data and conduct marketing activities, and we will always 
respect your privacy rights.
</p>
<p className="lead text-start">
Sundar will ask for your consent before processing your Personal Information, as required by law. This includes 
direct marketing purposes. You can withdraw your consent at any time through the Contact Us section.
Our Services are not for children under 16. If we find out that a child under 16 has provided their Personal 
Information through our Sites and/or Apps, we will remove their information. Parents or guardians should 
contact us at dataprotection@sundar.com if their child has provided information through our Sites and/or Apps.
 Who do we disclose and share your Personal Information with? We may share your Personal Information in the 
following situations:
Service Providers We provide your Personal Information to third-party service providers to assist us with: • 
Fulfilling or delivering your order; • Storing and securing data; • Processing payments; • Website and platform 
operations; • Cloud storage; • Marketing, analytics and fraud detection activities; and • Customer service. These 
service providers are only allowed to use your information in connection with the specific service they provide on 
our behalf.
Sundar Signature Services and affiliates, including Sundar and its services, are part of Cimpress plc group. We 
may share your info internally between our subsidiaries for this Privacy Policy. This enables you to use your 
Sundar account on our sites and apps, and add your contact details and content to it.
We use the information we collect to improve our products and services, including Sundar Print, Sundar Create, 
99Designs by Sundar, and Sundar x Wix. We also use it for analytics, customer service, and marketing. All 
Cimpress companies adhere to our Privacy Policy and limit access to Personal Information. When sharing 
Personal Information between subsidiaries in different regions, we use approved transfer mechanisms.
Our partners may access and process your Personal Information when you engage in their offers. They may 
also share Personal Information they collect about you with us. Review their privacy policies for more 
information. We may share your Personal Information with a third-party provider to integrate our service with 
theirs.
</p>
<p className="lead text-start">
We may share your Personal Information as required or permitted by law, to enforce our Terms and Conditions, 
to protect our rights or property or those of others, or in the event of a merger, acquisition, sale of assets, 
bankruptcy, or corporate reorganization. We may also respond to requests from law enforcement agencies that 
meet the standards for obtaining Personal Information in accordance with local laws and data protection 
requirements.
Sundar operates globally, and your information may be accessed, processed, stored, or transferred to countries 
with different data protection laws. We have safeguards in place to ensure that your Personal Information is 
protected. If we transfer your data to countries outside of EEA, the UK, or Switzerland, we ensure an adequacy 
decision by the European Commission or use approved transfer mechanisms. We also enforce technical and 
organizational security measures to protect your data.
An intra-group agreement ensures consistent and adequate protection for Personal Information transferred 
within the Cimpress plc group of companies.
You have rights to access and control the use of your information, subject to applicable laws and conditions</p>
</div>
        </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy