import React from "react";
import './Scroll.css';
const ScrollTop = (props) => {
   
  return (
    <div class="marquee">
    <span> Urgent.. Hiring for Marketing and Designer positions.. Please navigate to Notice page for details. (Contact No 9836882865/ 8100141018).. Send resume/CV directly to contactus@printsundar.com </span>
  </div>

    
  );
};

export default ScrollTop;