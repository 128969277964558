import React, { useState, useEffect,useRef, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { useSelector } from 'react-redux';

import StandardImage from '../images/Standard.PNG';
import NonTearable from '../images/NonTearable.png';
import QRCoadPremiuglossy from '../images/QRCoadPremiuglossy.png';
import SpotUVspecialVisitingCard from '../images/SpotUVspecialVisitingCard.png';
//import { useLocation } from "react-router";


import Transparentvisitingcard from '../images/TransparentVC.png';
//import CornerVisitingCard from '../images/CornerVisitingCard.png';
import MatteVC from '../images/MatteVC.png';
import SpecialcolourVC from '../images/SpecialcolourVC.png';

import Standard_VC from '../images/Standard_Glossy_VC.png';
import NonTearable_VC from '../images/Non_Tearable_VC.png';
import QR_Code_VC from '../images/VC_OrderSubmitPage.png';
import Velvet_Touch_VC from '../images/Velvet_Touch_VC.png';
import Transparent_VC from '../images/Transparent_VC.png';
import SpotUV_VC from '../images/SpotUV_VC.png';
import MatteFinished_VC from '../images/Matte_Finished_VC.png';
import SpecialColor_VC from '../images/Golden_Printed_VC.png';

import Magneticvisitingcard_VC from '../images/Magnetic_VC.png';
import ThreeDEffectvisitingcard_VC from '../images/3DEffect_VC.png';
import UniqueShapevisitingcard_VC from '../images/UniqueShape_VC.png';
import Photographicvisitingcard_VC from '../images/photographic_VC.png';


//import Matte_VC from '../images/SpecialcolourVC.png';
//import Special_Corner_Rectangle_VC from '../images/SpecialcolourVC.png';



import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link, useNavigate, NavLink } from "react-router-dom";
import {img} from "react-bootstrap/Image";

import VC_OrderSubmitPage from '../images/VC_OrderSubmitPage.png';
import {useLocation} from "react-router-dom";
import { Navbar, Main, Product, Footer } from "../components";

import {Button, ButtonGroup, ToggleButton, Col, Row } from 'react-bootstrap';

import TermsAndCondition from '../components/TermsAndCondition';

import {PriceList} from '../components/PriceList';
import {PriceList_VC_CornerRectangle} from '../components/PriceList_VC_CornerRectangle';
//
import {PriceList_VC_RoundedRectangle} from '../components/PriceList_VC_RoundedRectangle';
import { useAuth0 } from "@auth0/auth0-react";
import { usehistory } from "react-router-dom";
import Cart from '../pages/Cart';
import { isEmpty } from "validator";

//const checkList = ["Apple", "Banana", "Tea", "Coffee"];

  const SubmitOrder = () => {
     //localStorage.clear();
     const state = useSelector((state) => state.handleCart);
     const dispatch = useDispatch();
     
    //const navigate = useNavigate();
    const location = useLocation();
   
  const { loading, loginWithRedirect, logout,isAuthenticated, user } = useAuth0();
  
 const [isOpen, setIsOpen] = useState(false);
 const [isOpenBulk, setIsOpenBulk] = useState(false);
 const [imageHeaderTitle, setImageHeaderTitle] = useState("");

 //const [flagCalculatePrice, setFlagCalculatePrice] = useState(false);

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  //const [loading, setLoading] = useState(false);
  const [checkedqRCode, setCheckedqRCode] = React.useState(false);
  const [checkedLamination, setCheckedLamination] = React.useState(false);
  const [checkedSpotuv, setCheckedSpotuv] = React.useState(false);
  const [checkedTexture, setCheckedTexture] = React.useState(false);
  const [checkedTransparent, setCheckedTransparent] = React.useState(false);
  const [checkedSpecialColor, setCheckedSpecialColor] = React.useState(false);
  const [checkedEmbossing, setCheckedEmbossing] = React.useState(false);
  const [checkedUploadFrontPage, setCheckedUploadFrontPage] = React.useState(false);
  const [checkedUploadBackPage, setCheckedUploadBackPage] = React.useState(false);
  const [checkedUploadQRCodeImage, setcheckedUploadQRCodeImage] = React.useState(false);
  const [checkedDesign, setCheckedDesign] = React.useState(false);
  const [totalCost, settotalCost] = React.useState(0);

  const refStateUpdate = useRef(false);
  //const allInputs = { imgUrl: '' };

   let frontImage ={
    file:"",
    url:""
  };

  let backImage ={
    file:"",
    url:""
  };

  let qrImage ={
    file:"",
    url:""
  };

   const [product, setProduct] = React.useState({
    orderName:"Visiting Card",
    booking_type:"",
    cardType: "",
    cardSize: "",
    mediaType: "",
    quality: "",
    gsmValue: "",
    quantity: "",
    qRCode: false,
    qRSingleBoth: "Back",
    lamination: checkedLamination,
    laminationSingleBoth: "",
    spotUV: checkedSpotuv,
    spotUVSingleBoth: "",
    texture: checkedTexture,
    textureSingleBoth: "",
    transparent: checkedTransparent ,
    transparentSingleBoth: "",
    specialColor: checkedSpecialColor,
    specialColorSingleBoth: "",
    embossing: checkedEmbossing,
    embossingSingleBoth: "",
    boolUploadFrontImage:checkedUploadFrontPage,
    frontImagePath:"",
    boolUploadBackImage:checkedUploadBackPage,
    backImagePath:"",
    QRCodeImagePath:"",
    price: 10,
    FirstPayment:0,
    SecondPayment:0,
    FirstPaymentTranId: "",
    SecondPaymentTranId: "",
    frontImageFile:"",
    backImageFile:"",
    QRImageFile:"",
    
  })

   const [image, setImage] = React.useState({

    blobData:"",
    fileName: "",
    fileType: "",
  })

  /*
  useEffect(() => {
    window.location.reload();
  }, [reload])
  */
  const converttoBase64 =(file)=>{
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return reader.result;

}

  const handleChangeQRCode = () => {
    setCheckedqRCode(!checkedqRCode);
    setProduct((product) => ({ ...product, qRCode: !checkedqRCode}));
    //console.log(" Ln 93 QR code value = " + product.qRCode);
  };

 function handleChangeDesignOnly() {
 
  setCheckedDesign(!checkedDesign);
 
}

  const handleChangeUploadQRCode = () => {
    setcheckedUploadQRCodeImage(!checkedUploadQRCodeImage);
    
  };

  const handleChangeLamination = () => {
    setCheckedLamination(!checkedLamination);
    setProduct((product) => ({ ...product, lamination: !checkedLamination}));
  };
  const handleChangeSpotuv = () => {
    setCheckedSpotuv(!checkedSpotuv);
  };
  const handleChangeTexture = () => {
    setCheckedTexture(!checkedTexture);
    setProduct((product) => ({ ...product, texture: !checkedTexture}));
  };
  const handleChangeTransparent = () => {
    setCheckedTransparent(!checkedTransparent);
  };
  const handleChangSpecialColor = () => {
    setCheckedSpecialColor(!checkedSpecialColor);
    setProduct((product) => ({ ...product, specialColor: !checkedTexture}));
  };
  const handleChangeEmbossing = () => {
    setCheckedEmbossing(!checkedEmbossing);
  };
  const handleChangeUploadFrontPage = () => {
    //console.log("handleChangeUploadFrontPage checkedUploadFrontPage value = " + checkedUploadFrontPage);
    setCheckedUploadFrontPage(!checkedUploadFrontPage);

    if(checkedUploadFrontPage === true)
    {
       product.frontImagePath = "";
      // console.log(" Ln 89 checkedUploadFrontPage checkedUploadFrontPage value = " + product.frontImagePath);
    }


  };
  const handleChangeUploadBackPage = () => {
    setCheckedUploadBackPage(!checkedUploadBackPage);
  };

  let componentMounted = true;
  const disCountPercentage = (totalCost)=>{


  }


  const clearDataFromLocalStorage =()=>{
      
    //alert("Ln 227 Submit Order Cleared all data from local storage..");
try{
    localStorage.removeItem("Product");
    localStorage.removeItem("orderName");
    localStorage.removeItem("CardType");
    localStorage.removeItem("CardSize");
    localStorage.removeItem("MediaType");
    localStorage.removeItem("Quantity");
    localStorage.removeItem("Price");
    localStorage.removeItem("CardType");
    localStorage.removeItem("AdvancePayment");
    localStorage.removeItem("orderName");
    localStorage.removeItem("rzp_checkout_anon_id");
  }catch(e)
  {
    console.log("Error in deleting localstorage data..Error =" + e);

  }
   
  }

  const calculatePrice= () => {
    
    //console.log("Ln 133 calculatePrice called = ");
    if(product.cardType == "Corner Rectangle" && product.mediaType === "Paper")
    {
      //alert("back Image File name = " +product.backImageFile.name);
      //alert("Front Image File name = " +product.frontImageFile.name);
      var unitBasicCost = 0;
      if(product.frontImageFile && product.frontImageFile.name.length >4)  //single page print
      {  unitBasicCost = GetVCPriceListCornerRectangle(product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Single")
         console.log("Single page string = " + product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Single"); 
         //alert("Single page cost =" + unitBasicCost.price);
      }
      if(product.frontImageFile && product.frontImageFile.name.length >4 && product.backImageFile && product.backImageFile.name.length >4) //Both page print 
      {
         unitBasicCost = GetVCPriceListCornerRectangle(product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Double")
         console.log("Double page string = " +product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Double");
         //alert("Double page cost =" + unitBasicCost.price);
      }
    }

    // Corner Rectangle cCalculation

    if(product.cardType == "Rounded Rectangle" && product.mediaType === "Paper")
    {
      //alert("back Image File name = " +product.backImageFile.name);
      //alert("Front Image File name = " +product.frontImageFile.name);
      var unitBasicCost = 0;
      if(product.frontImageFile && product.frontImageFile.name.length >4)  //single page print
      {  unitBasicCost = GetVCPriceListRoundedRectangle(product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Single")
         console.log("Single page string = " + product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Single"); 
         //alert("Single page cost Rounded Rectangle =" + unitBasicCost.price);
      }
      if(product.frontImageFile && product.frontImageFile.name.length >4 && product.backImageFile && product.backImageFile.name.length >4) //Both page print 
      {
         unitBasicCost = GetVCPriceListRoundedRectangle(product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Double")
         console.log("Double page string = " +product.cardType+"_"+product.cardSize+"_GSM_"+product.gsmValue+"_Double");
         //alert("Double page cost Rounded Rectangle =" + unitBasicCost.price);
      }
    }
    //const cardTypePrice = GetVCPriceList(product.cardType);
    //console.log("Ln 136 Total Price = " + cardTypePrice.price);
    //const cardSizePrice = GetVCPriceList(product.cardSize);
    //console.log("Ln 138 Total Price = " + cardSizePrice.price);
    //const mediaTypePrice = GetVCPriceList(product.mediaType);
    //console.log("Ln 140 Total Price = " + mediaTypePrice.price);
    //const qualityPrice = GetVCPriceList(product.quality);
    //console.log("Ln 149 QR Code = " + product.qRCode);
    //const gsmValue = GetVCPriceList(product.gsmValue);
    //console.log("Ln 148 gsmValue Cost = " + gsmValue.price);
    const unitCost = (unitBasicCost.price + 0.3); // Trsnsport cost 0.3
    //console.log("Ln 223 unitCost = " + unitCost);
    //console.log("Ln 224 Total Quantity = " + product.quantity);
   let totalCostVal = 0;
     
    if(product.quantity === "200")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.84;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 200 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "300")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.78;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 300 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "400")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.73;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 400 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "500")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.68;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 500 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "1000")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.62;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 1000 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "1500")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.6;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 1500 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "2000")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.57;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 2000 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "2500")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.55;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 2500 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "5000")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.49;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
      // alert(" Value for 5000 Unit = " + Math.round(totalCostVal));
    }
    else if(product.quantity === "10000")
    {
       totalCostVal =  (unitCost * product.quantity) * 0.47;
       //console.log("Ln 268 Total totalCostVal = " + Math.round(totalCostVal));
       //alert(" Value for 10000 Unit = " + Math.round(totalCostVal));
    }else{

      totalCostVal =  (unitCost * product.quantity)
      //alert(" Value for "+ product.quantity + Math.round(totalCostVal));
    }
   /* if(product.quantity === 200)
    {
       totalCostVal =  unitCost * product.quantity * 0.84;
       console.log("Ln 273 Total totalCostVal = " + Math.round(totalCostVal));
    }
    */
    //const totalCostVal =  unitCost * product.quantity;
    console.log("Ln 270 Total Price = " + Math.round(totalCostVal));
    const finalPrice = Math.round(totalCostVal);
    settotalCost(Math.round(totalCostVal));
    console.log("Ln 229 totalCost = " + Math.round(totalCostVal));
    setProduct((product) => ({ ...product, price: Math.round(totalCostVal)}));
    clearDataFromLocalStorage();
    if (isAuthenticated) {
      localStorage.setItem("Product", JSON.stringify({product}));
      localStorage.setItem("orderName", JSON.stringify(product.orderName));
      localStorage.setItem("CardType", JSON.stringify(product.cardType));
      localStorage.setItem("CardSize", JSON.stringify(product.cardSize));
      localStorage.setItem("MediaType", JSON.stringify(product.mediaType));
      localStorage.setItem("Quantity", JSON.stringify(product.quantity));
      localStorage.setItem("Price", JSON.stringify((Math.round(totalCostVal))));
     
      }
      
    refStateUpdate.current = true;
  };

  const showModal = () => {
      setIsOpen(true);
  };
    
  const hideModal = () => {
    setIsOpen(false);
  };

  const addProduct = (product) => {
    console.log("Called addProduct() product.price = "+ product.price);
    dispatch(addCart(product));
  }
  const deleteProduct = (product) => {
    console.log("Called deleteProduct() product.price = ");
    dispatch(delCart(product));
  }
  const handleChange = (evt) => {
    const value = 
    evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setProduct({
      ...product,
      [evt.target.name]: value
    });
  };

  const handleChangSingleBoth = (evt) => {
    const value = evt.target.value;

    console.log("handleChangSingleBoth value = " + value);
    setProduct({
      ...product,
      [evt.target.name]: value
    });
  };

  //
  const handlepromoCode = (evt) => {
    const value = 
    evt.target.type ===  evt.target.value;
    setProduct({
      ...product,
      [evt.target.name]: value
    });
  };


function handleChangeFrontImageFileUpload(event) {
 
  //const value = (event.target.files[0]);
  //console.log("Front Image File  = " + value.name);
  //setFrontImagePath(URL.createObjectURL(event.target.files[0]));
  //setImage(event.target.files[0], URL.createObjectURL(event.target.files[0]));
  
  //product.images.push(image);
  //console.dir(image);
  
  setProduct({
    ...product,
    [event.target.name]: URL.createObjectURL(event.target.files[0])
    //[event.target.name]: converttoBase64(event.target.files[0])
  });
  setProduct((product) => ({ ...product, frontImageFile: event.target.files[0]}));
  //product.push(image);
  //console.dir(product);
}

/*
const createImageFileArray=()=>{

  console.dir(" Ln 239 called createImageFileArray = ");  
  console.dir(" product.frontImageFile = " + product.frontImageFile);  
  if(product.frontImageFile)
  {
    frontImage.file = product.frontImageFile;
    frontImage.url = URL.createObjectURL(product.frontImageFile);
    product.images.push(frontImage);

  }
  if(product.backImageFile)
  {
    backImage.file = product.backImageFile;
    backImage.url = URL.createObjectURL(product.backImageFile);
    setProduct((product) => ({ ...product, images.));

  }
  if(product.QRImageFile)
  {
    qrImage.file = product.QRImageFile;
    qrImage.url = URL.createObjectURL(product.QRImageFile);
    product.images.push(qrImage);

  }
  console.dir(product);

}
*/

function handleChangeBackImageFileUpload(event) {
 
  const value = (event.target.files[0]);
  console.log("Back Image File = " + value);
   setProduct({
     ...product,
     [event.target.name]:  URL.createObjectURL(event.target.files[0])
   });
   setProduct((product) => ({ ...product, backImageFile: event.target.files[0]}));
   //console.dir(" Ln 225 Front Image File Name = " + product.backImageFile);  
   //console.log("Back Image path = " + product.backImagePath);
 
 }
 
 function handleChangeQRCodekFileUpload(event) {
 
  const value = (event.target.files[0]);
 
  setProduct({
   ...product,
   [event.target.name]:  converttoBase64(event.target.files[0])
 });
 setProduct((product) => ({ ...product, QRImageFile: value}));
 //console.log("QR Code Image path = " + product.QRCodeImagePath);
}
  //####################################

  const ShowPaperTexture = () => {
    return (
      <>
          <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={StandardImage} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Standard</h5>
                <h5 className="card-title text-center">100 Starting at Rs 190.00</h5>
              </div>
              <Link to={"/product/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={NonTearable} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Non Tearable Visiting  Card</h5>
                <h5 className="card-title text-center">100 Starting at Rs 200.00</h5>
              </div>
              <Link to={"/product/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={QRCoadPremiuglossy} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">QR Code Premium Glossy</h5>
                <h5 className="card-title text-center">100 Starting at Rs 190.00</h5>
              </div>
              <Link to={"/product/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={SpotUVspecialVisitingCard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Spot UV Visiting Card</h5>
                <h5 className="card-title text-center">100 Starting at Rs 205.00</h5>
              </div>
              <Link to={"/product/"  } className="btn btn-dark m-1">
                    Buy Now
              </Link>
              
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  const GetVCPriceList=(name) =>{
    const arrayObj = PriceList.find(item => item.name === name);
    if(arrayObj)
      return arrayObj;
    else
      return 'Error';
      
  }
  
  const GetVCPriceListCornerRectangle=(name) =>{
    const arrayObj = PriceList_VC_CornerRectangle.find(item => item.name === name);
    if(arrayObj)
      return arrayObj;
    else
      return 'Error';
      
  }

  const GetVCPriceListRoundedRectangle=(name) =>{
    const arrayObj = PriceList_VC_RoundedRectangle.find(item => item.name === name);
    if(arrayObj)
      return arrayObj;
    else
      return 'Error';
      
  }

  useEffect(() => {
    if(refStateUpdate.current) {
       refStateUpdate.current = false;
       console.log("Ln 360 Going to print...product.frontImageFile.name =" + product.frontImageFile.name)
       //createImageFileArray();
       //console.dir(product);
       console.log("Ln 405 Is logged in isAuthenticated = " + isAuthenticated);
       //addProduct(product);
    }
  }, [product.price])
  

  const fillData = ()=>{
    console.log("Ln 314 fillData called.. Length = " + product.length);
    let i = 0;
    for(i =0; i< product.length; i++)
    {
      if(product[i] === null || product[i] === "")
      console.log("Ln 318 product[i]  + " + product[i]);
      product[i] = "NA";

    }

  }

  //  showModal

  const ShowTCPrint=(event) =>{
   
    //console.dir(product);
    fillData(product)
    console.log("Ln 316 after calling  console.dir(product);..");
    console.dir(product);
    setProduct((product) => ({ ...product, booking_type: "Print"}));
    calculatePrice();
    //hideModal();
    //showModal();
    //console.log("Value of Param =  Ln 435");
     addProduct(product);
      //const param = Cart(state);
    //console.log("Value of Param =  Ln 438" + {param});
    //navigate("/Cart", Cart(state.length));
    alert("Your Order Successfully Saved. Click Next to Proceed..");
  }

  const ShowTCDesign=() =>{
    setProduct((product) => ({ ...product, booking_type: "Design"}));
    calculatePrice();
    showModal();
  }

  const GetHeaderImage=()=>{

    setImageHeaderTitle(location.state.title);

  }

  const getTitle=()=>{

    var Id = location.state.title;
    if(Id ==="Standard_VC")
     return "Standard Visiting Card";
    else  if(Id ==="NonTearable_VC")
     return "Non-Tearable Visiting Card";
    else if(Id ==="QRCodePremium_VC")
     return "QR Code Premium Glossy Visiting Card";
    else if(Id ==="Transparent_VC")
     return "Transparent Visiting Card";
    else if(Id ==="SpotUV_VC")
     return "Spot UV VisitingCard";
    else if(Id ==="Matte_VC")
     return "Matte Visiting Card";
    else if(Id ==="Special_Color_VC")
     return "Special Colour Visiting Card";
     else if(Id ==="Velvet_Touch_VC")
     return "Velvet Touch Visiting Card";
     else if(Id ==="Magnetic_VC")
     return "Magetic Visiting Card";
     else if(Id ==="3D_Effect_VC")
     return "3D Effect Visiting Card";
     else if(Id ==="UniqueShape_VC")
     return "Unique Shape Visiting Card";
     else if(Id ==="Photographic_VC")
     return "Photographic Visiting Card";
    else
     return "Standard Visiting Card";
  }

  return (
    <>
    <Navbar />
    <div className="container my-3 py-8">
    <div className="row">
    <div className="col-12 ">
      {(location.state.title === "Standard_VC") &&
         <img src={Standard_VC} class="img-fluid" alt="header image"/>
      }
      {(location.state.title === "NonTearable_VC") &&
         <img src={NonTearable_VC} class="img-fluid" alt="header image"/>
      }
      {(location.state.title === "QRCodePremium_VC") &&
         <img src={QR_Code_VC} class="img-fluid" alt="header image"/>
      }
       {(location.state.title === "Velvet_Touch_VC") &&
         <img src={Velvet_Touch_VC} class="img-fluid" alt="header image"/>
      }
        {(location.state.title === "Transparent_VC") &&
         <img src={Transparent_VC} class="img-fluid" alt="header image"/>
      }
       {(location.state.title === "SpotUV_VC") &&
         <img src={SpotUV_VC} class="img-fluid" alt="header image"/>
      }
      {(location.state.title === "Matte_VC") &&
         <img src={MatteFinished_VC} class="img-fluid" alt="header image"/>
      }
      {(location.state.title === "Special_Color_VC") &&
         <img src={SpecialColor_VC} class="img-fluid" alt="header image"/>
      }
      {(location.state.title === "Magnetic_VC") &&
         <img src={Magneticvisitingcard_VC} class="img-fluid" alt="header image"/>
      }
       {(location.state.title === "3D_Effect_VC") &&
         <img src={ThreeDEffectvisitingcard_VC} class="img-fluid" alt="header image"/>
      }
       {(location.state.title === "UniqueShape_VC") &&
         <img src={UniqueShapevisitingcard_VC} class="img-fluid" alt="header image"/>
      }
       {(location.state.title === "Photographic_VC") &&
         <img src={Photographicvisitingcard_VC } class="img-fluid" alt="header image"/>
      }

    
      </div> 
      <div className="container my-3 py-3">
    <div className="row">
    <div className="col-4">
      </div>
       <div className="col-8">
         <h2 className="display-12 text-left">{getTitle()}</h2>
         <h5 className="display-10 text-left">Personalized cards with a professional look.</h5>
            <ul>
              <li>
                  <h6 className="display-8 text-left">4000+ design options available</h6>
              </li>
              <li>
                  <h6 className="display-8 text-left">Standard glossy or matte paper included</h6>
              </li>
              <li>
                  <h6 className="display-8 text-left">Dimension shown on the design page includes bleed area (safety area), the final card size will be 8.9 cm x 5.1 cm</h6>
              </li>
              <li>
                  <h6 className="display-8 text-left">Stretch your design up to the Bleed area to avoid white borders appearing around your card.</h6>
              </li>
              <li>
                  <h6 className="display-8 text-left">Keep all your information within the safety area.</h6>
              </li>
              <li>
                  <h6 className="display-8 text-left">Choose bold fonts size 10 and above when using white text.
        <hr /></h6>
              </li>
            </ul>
     </div>
    </div>
  
    </div>
      </div>
    </div>
    <div className="container bg-primary text-white my-2 py-4">
        <h1 className="text-left">Order Details for {getTitle()}</h1>
        <hr />
        <form class="row g-3">
      
  <div class="col-md-3">
    <label for="inputState" class="form-label text-white">Type</label>
    <select id="inputState" class="form-select" name="cardType" onChange={handleChange} value={product.cardType}>
      <option selected>Choose...</option>
      <option>Corner Rectangle</option>
      <option>Rounded Rectangle</option>
      <option>Square</option>
      </select>
  </div>
  <div class="col-md-3">
    <label for="inputState" class="form-label text-white">Size</label>
    <select id="inputState" class="form-select" name="cardSize" onChange={handleChange} value={product.cardSize}>
      <option selected>Choose...</option>
      <option>8.9 x 5.1</option>
      <option>9.1 x 5.5</option>
      <option>6.35 x 6.35</option>
      </select>
  </div>
  <div class="col-md-3">
    <label for="inputState" class="form-label text-white" >Media Type</label>
    <select id="inputState" class="form-select" name="mediaType" onChange={handleChange} value={product.mediaType}>
      <option selected>Choose...</option>
      <option>Paper</option>
      <option>Board</option>
      <option>PVC</option>
      <option>Non-Tearable Sheet</option>
      <option>Velvet</option>
      <option>Transparent</option>
      </select>
  </div>
  
  <div class="col-md-3">
    <label for="inputState" class="form-label text-white">Quality</label>
    <select id="inputState" class="form-select" name="quality" onChange={handleChange} value={product.quality}>
      <option selected>Choose...</option>
      <option>Glossy</option>
      <option>Matte</option>
     </select>
  </div>

  <div class="col-md-6 mt-3">
    <Row>
      <Col>
       <div class="form-check pt-4" >
       <input class="form-check-input" type="checkbox" name="qRCode" checked={checkedqRCode} onChange={handleChangeQRCode} />
       <label class="form-check-label" for="flexCheckChecked" >
       QR code
       </label>
       </div>
       </Col>
    <Col>
    <div class=" ">
    { checkedqRCode && 
    
    <>
    <label for="inputState" class="form-label text-white">Front/Back Side (Default Back)</label>
    <select id="inputState" class="form-select" name="qRSingleBoth" onChange={handleChangSingleBoth} value={product.qRSingleBoth}>
    <option >Back</option>
    <option >Front</option>
    </select>
  </>
    }
       </div>
    </Col>
    
    </Row>
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" name="lamination" checked={checkedLamination} onChange={handleChangeLamination} />
       <label class="form-check-label" for="flexCheckChecked" >
       Lamination
       </label>
       </div>
       </Col>
    <Col>
    { checkedLamination &&  
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="laminationSingleBoth" onChange={handleChangSingleBoth} value={product.laminationSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    <option >Both</option>
   </select>
  </>
    
    }
    </Col>
    </Row>
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" name="spotUV" checked={checkedSpotuv} onChange={handleChangeSpotuv} />
       <label class="form-check-label" for="flexCheckChecked">
       Spot UV
       </label>
       </div>
       </Col>
    <Col>
    { checkedSpotuv && 
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="spotUVSingleBoth" onChange={handleChangSingleBoth} value={product.spotUVSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    <option >Both</option>
   </select>
  </>
    }
    </Col>
    </Row>
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" name="texture" checked={checkedTexture} onChange={handleChangeTexture} />
       <label class="form-check-label" for="flexCheckChecked">
       Texture
       </label>
       </div>
       </Col>
    <Col>
    { checkedTexture && 
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="textureSingleBoth" onChange={handleChangSingleBoth} value={product.textureSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    <option >Both</option>
   </select>
  </>
    }
    </Col>
    </Row>    
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" id="flexCheckChecked" name="transparent" checked={checkedTransparent} onChange={handleChangeTransparent} />
       <label class="form-check-label" for="flexCheckChecked">
       Transparent
       </label>
       </div>
       </Col>
    <Col>
    { checkedTransparent && 
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="transparentSingleBoth" onChange={handleChangSingleBoth} value={product.transparentSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    </select>
  </>
    }
    </Col>
    </Row>    
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" id="flexCheckChecked" name="specialColor" checked={checkedSpecialColor} onChange={handleChangSpecialColor}  />
       <label class="form-check-label" for="flexCheckChecked">
       Special Color (Heat Print)
       </label>
       </div>
       </Col>
    <Col>
    { checkedSpecialColor &&  
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="specialColorSingleBoth" onChange={handleChangSingleBoth} value={product.specialColorSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    <option >Both</option>
   </select>
  </>
    }
    </Col>
    </Row>    
    <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" type="checkbox" id="flexCheckChecked" name="embossing" checked={checkedEmbossing} onChange={handleChangeEmbossing} />
       <label class="form-check-label" for="flexCheckChecked">
       Embossing
       </label>
       </div>
       </Col>
    <Col>
    { checkedEmbossing && 
    
    <>
    <label for="inputState" class="form-label text-white">Single/Both Side</label>
    <select id="inputState" class="form-select" name="embossingSingleBoth" onChange={handleChangSingleBoth} value={product.embossingSingleBoth}>
    <option selected>Choose..</option>
    <option >Single</option>
    </select>
  </>

    }
    </Col>
    </Row>
   </div>

   <div class="col-md-6 mt-3">
    <Row>
      <Col>
      {product.mediaType==='Paper' && 
     <>
    <label for="inputState" class="form-label text-white">GSM</label>
    <select id="inputState" class="form-select" name="gsmValue" onChange={handleChange} value={product.gsmValue}>
      <option selected>Choose...</option>
      <option>160-180</option>
      <option>200-220</option>
      <option>250</option>
      <option>300</option>
      <option>350</option>
     </select>
     </>
      }
     </Col>
     
     <Col>
    
    <label for="inputState" class="form-label text-white">Quantity</label>
   <select id="inputState" class="form-select" name="quantity" onChange={handleChange} value={product.quantity}>
      <option selected>Choose...</option>
      <option>100</option>
      <option>200</option>
      <option>300</option>
      <option>400</option>
      <option>500</option>
      <option>1000</option>
      <option>2000</option>
      <option>5000</option>
      <option>10000</option>
      </select>
      
      </Col>
      </Row>

    <Row>
    <label class="form-check-label mt-4 ml-2" for="flexCheckChecked">Please upload existing design template if any..(Any Image file) </label>  
    </Row>  
    <Row>
        <Col>
        <div class="form-check pt-3 ml-2 mt-2" >
       <input class="form-check-input" type="checkbox" name="boolUploadFrontImage" checked={checkedUploadFrontPage} onChange={handleChangeUploadFrontPage}  />
       <label class="form-check-label" for="flexCheckChecked">
       Upload front Page of Design?
       </label>
       </div>
        </Col>
        <Col>
        <div class="mt-4">
        {
            checkedUploadFrontPage &&
           <input id="uploadfront" type="file" accept="image/*" name="frontImagePath"  onChange={handleChangeFrontImageFileUpload}
           />
        }
           </div>
        </Col>
      </Row>
      <Row>
        <Col>
        <div class="form-check pt-3 ml-2 mt-2" >
       <input class="form-check-input" type="checkbox"  name="boolUploadBackImage" checked={checkedUploadBackPage} onChange={handleChangeUploadBackPage}  />
       <label class="form-check-label" for="flexCheckChecked">
       Upload second Page of Design?
       </label>
       </div>
       <div>
       </div>
        </Col>
        <Col>
        <div class="mt-4">
          {
            checkedUploadBackPage &&
            <input id="uploadback" type="file" accept="image/*"  name="backImagePath"  onChange={handleChangeBackImageFileUpload}/>
          }
           </div>
        </Col>
      </Row>
       <Row>
       <Col>
       {checkedqRCode &&
        <div class="form-check pt-3 ml-2 mt-2" >
       <input class="form-check-input" type="checkbox"  name="boolUploadQRCodeImage" checked={checkedUploadQRCodeImage} onChange={handleChangeUploadQRCode}  />
       <label class="form-check-label" for="flexCheckChecked">
       Upload QR Code Image?
       </label>
       </div>
       }
       </Col>
        <Col>
        <div class="mt-4">
          {checkedqRCode &&
            checkedUploadQRCodeImage &&
            <input id="uploadback" type="file" accept="image/*"  name="QRCodeImagePath"  onChange={handleChangeQRCodekFileUpload}/>
          }
           </div>
        </Col>
        </Row>
        <Col>
        <div class="form-check pt-3 ml-2 mt-2" >
       <input class="form-check-input" type="checkbox"  name="checkedDesign" checked={checkedDesign} onChange={handleChangeDesignOnly}  />
       <label class="form-check-label" for="flexCheckChecked">
       Submit Order for Design Only?
       </label>
       </div>
       </Col>
       <Row>
       <Col>
       {
        <div class="form-check pt-3 ml-2 mt-2" >
         <label class="form-check-label" for="flexCheckChecked">
           Please enter promo code 
        </label>
       </div>
       }
       </Col>
        <Col>
        <div class="mt-4">
         <input id="promoCode" type="text"  name="promoCode"  onChange={handlepromoCode}/>
    
           </div>
        </Col>

       </Row>
      </div>
      
      <div class="col-md-6 mt-3">
      </div>
      <div class="col-md-6 mt-3">
      <Row>
      <Col>
      { product.frontImagePath && !checkedDesign &&
          <button type="button"  class="btn btn-primary bg-white text-black align-left" name ="price" onClick={ShowTCPrint}  value={product.price}>Order for Print</button>
        }
       </Col>
       {checkedDesign &&
         <Col>
        <button type="button"  class="btn btn-primary bg-white text-black align-left" onClick={() => ShowTCDesign()}>Order for Design</button>
        </Col>
       }
      {
      state.length > 0 &&
     <Col>
     <NavLink to="/cart" className="btn btn-primary bg-white text-black align-left">Next ({state.length}) </NavLink>
     </Col>
     }
      </Row>
     </div>
   </form>
</div> 
<Footer/>
<TermsAndCondition isOpen={isOpen} hideModal={hideModal}/>
</>
);
};

export default SubmitOrder;
