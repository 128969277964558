import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Container, Form, Button, Row , Col } from 'react-bootstrap'
import { Document, Page } from 'react-pdf';
import { Link, useNavigate } from "react-router-dom";

const TermsAndCondition =({isOpen, hideModal}) =>{

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  let handleSubmit = async (e) => {
    
    console.log("Submit Button Clicked..");

  }

  const displayLogInScreen = () => {
    

  };
 
  return (
      <> <Modal 
      show={isOpen} 
      onHide={hideModal} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     >
     <Modal.Header style={{backgroundColor: "skyBlue"}} closeButton>
      <Modal.Title id="contained-modal-title-vcenter" color="white">
       Terms and Conditions
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
    <Form >
    <div class="bg"></div>
    <div class="terms-box">
        <div class="terms-text">
            <h5>Greetings User</h5>
            <h3>Terms and condition for the customer</h3>
            <p>1. In case of placing order the customers firstly will take clear conception about the company's terms and 
conditions and only thereafter they will give consent after agreeing said terms and conditions . 
2. Customer must give their authorised stamp and signature in course of making agreement , final design and 
so also placing final order. 
3. Customer will have to make deposit 60% to 80% money as an advance at the time of issuing work order. 
4. Every money transaction will be made through online to the company's account. 
5 After payment through online the company will give message to the customer in respect of advance 
payment. 
6 No advance money is refundable in case of any order for any reason. 
7 After work order customer can cancel the order with 40 hours, otherwise they will have to make full 
payment. 
8 After receiving advance money company will inform about the expected date of delivery. 
9 On delivery the customer must pay the amount in full. 
10 After delivery customer must give message at once to the company about the successful delivery. 
11 After approval of final design no alteration would be done regarding design and quantity in any way. 
12 The company will not take any sort of responsibility for cash transaction of any person in any way. 
13 The company is not bound to exhaust any work if advance money is not pay to it. 
14 After delivery the company must take legal action for making delay in case of full payment. 
15 Date of delivery may be changed in case of natural calamity or any social hindrance. 
16 The company is not bound to refund the advance money in case of cancellation of work order. 
17 The customer will have a clear concept about colour, material, size etc before placing work order 
otherwise the company will not take any responsibility. 
18 The company will not provide any credit facility for any sort of work order ( In that case the customer will 
bear the credit facility upon his / her own shoulder ).
19 After approval of design the customer can not make any changes or alteration. 
20 The customer must upload the delivery location and he or she must bear the transport charges for it. 
21 The subject matter and quantity relating to the work order must always be sent to the company through 
online. 
22. According to the subject matter the specific design after approval, the approved copy there of must be 
uploaded in the company's website.</p>

       <h4>I Agree To The <span> Terms And Conditions</span> And I Read The Privacy Notice</h4>
       <div class="buttons">
       
       </div>
            </div>
    </div>
      </Form>
        </Modal.Body>
      <Modal.Footer>
           <Link to={"/login/"} onClick={hideModal} className="btn btn-success m-1">Accept</Link>
           <Button className="btn btn-danger m-1" onClick={hideModal}>Decline</Button>
      </Modal.Footer>
    </Modal></>
    )
  };

export default TermsAndCondition