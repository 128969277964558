import React from "react";
import {Carousel } from 'react-bootstrap'; 
import { useAuth0 } from "@auth0/auth0-react";
 import Footer from '../components/Footer';

const Home = () => {
  const { isAuthenticated, logout } = useAuth0();
  return (
    <>
     <div className='p-5'>  
    <Carousel>  
    <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/frontpage.png" 
      alt="First slide"  
    />  
  
    <Carousel.Caption>  
      <h3>Welcome </h3>  
      <p>Welcome to Sundar Printing..</p>  
    </Carousel.Caption>  
  </Carousel.Item>  
 
  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/LetterHead.png" 
      alt="Third slide"  
    />  
    <Carousel.Caption>  
      <h3>Stationary </h3>  
      <p>Stationary and Crafts</p>  
    </Carousel.Caption>  
  </Carousel.Item>  
  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/Stickers.png"
      alt="Fourth slide"  
    />  
    <Carousel.Caption>  
      <h3>Stickers</h3>  
      <p>Labels and Stickers</p>  
    </Carousel.Caption>  
  </Carousel.Item>

  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/VisitingCards.png"
      alt="Fifth slide"  
    />  
  
    <Carousel.Caption>  
      <h3>Visiting Cards</h3>  
      <p>Visiting Cards</p>  
    </Carousel.Caption>  
  </Carousel.Item>  

  <Carousel.Item>  
    <img  
      className="d-block w-100"  
      src="./assets/Posters.png"
      alt="Fifth slide"  
    />  
    <Carousel.Caption>  
      <h3>Poster</h3>  
      <p>Poster </p>  
    </Carousel.Caption>  
  </Carousel.Item>
</Carousel>  
</div>  

    </>
     
  );
};

export default Home;
