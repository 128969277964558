import React from 'react'
import { Footer, Navbar } from "../components";

import {img} from "react-bootstrap/Image";

import AboutUsImage from '../images/about us.png';

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="row">
    <div className="col-12 ">
    
     <img className="card-img-top img-fluid" src={AboutUsImage} alt="" height={160} />
      </div> 
      </div>
      <div className="container my-3 py-3">
     <p class="lead text-start fw-bold">About Us:</p>
<p className="lead text-start" >“SUNDAR” a graphics designing and printing technology company, has many years of experience in that field. We have the wide range of 
clients and providing the service as required their need in real time. We provide the service of various print technologies like screen, offset, 
digital, gravure, solvent (flex, vinyl, one-way vision, etc.) & photo printing needs of customers under a single roof. The company is 
providing with latest and best technology, in-house testing and quality assurance facilities to produce quality products. We focus on 
providing best solutions to our esteemed customers/clients and accordingly suggest best products to them suitable their applications and 
requirements.</p>

<p class="lead text-start fw-bold">Our Mission & Goal:</p>
<p className="lead text-start">        
“SUNDAR” is a quality based up growing company. We are dedicated to satisfying our customers/clients. We make every effort to 
understand and fulfill the expectations of our customers/clients. We make quality a component that goes into step by step process to final 
output received by our customers. Quality is our motto. We believe in respecting our customers/ clients, listening to their ideas and 
understanding their expectations from us and providing the best solutions. We eager to make a healthy relationship with our customers, 
suppliers, vendors and employees by establishing reliability and fairness with a friendly environment. We upgrade and implement the 
emerging technology to provide best quality and cost effective products on real time.
        </p>
        </div>
      <Footer />
    </>
  )
}

export default AboutPage