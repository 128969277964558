import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Footer, Navbar } from "../components";
import { Document, Page } from 'react-pdf';
import ScrollTop from '../components/ScrollTop';
import advertisement1 from '../images/Vancacy_1.PNG';
import advertisement2 from '../images/Vancacy_2.PNG';
import './Vacancy.css';

export default function Vacancy()  {

     return (
      <>
        <Navbar />
        <ScrollTop/>
        <div className="DivAdvt" >
        <img className="ImgAdvt"
                                  src= {advertisement1} 
                                  alt="Advertisement_1"
                                
                                />
      <p></p>
    </div>
    <div className="DivAdvt" >
        <img className="ImgAdvt"
                                  src= {advertisement2} 
                                  alt="Advertisement_2"
                                
                                />
      <p></p>
    </div>
        <Footer />
      </>
    );
  };