import {React, useState,useEffect} from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import axios from "axios";
import StandardImage from '../images/Standard.PNG';
import { Checkbox, FormCheck, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import './Cart.css';
import { useAuth0 } from "@auth0/auth0-react";
import InvoiceForm from '../PDF/InvoiceForm';
import emailjs from "@emailjs/browser";

//const crypto = require("crypto");

const Cart = () => {
  const { isAuthenticated, logout } = useAuth0();
  const state = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const [price, SetTotalPrice]= useState(0);  
  const [bookingAmount, SetBookingAmount]= useState();  
  const [fullPaymentChecked, SetFullPaymentChecked] = useState(false);
/*
  useEffect(() => {
    emailjs.init("xkVfdOdrH64JDAp3E");
    
  }, [])
  */

  useEffect(() => {
   
     emailjs.init("xkVfdOdrH64JDAp3E");
    if (isAuthenticated) {
      //const productData = JSON.parse(localStorage.getItem('Product'));
      const price = JSON.parse(localStorage.getItem('Price'));
      console.log(" Ln 37 Product data inideCart.js " +price);
      if(price){

        //dispatch(addCart(productData));
        SetTotalPrice(price);
        //console.log("Product Data")

      }
    }
    else{
       localStorage.removeItem("Product");

    }
    
  }, [isAuthenticated]);

 const { googlePayClient } = window;

const baseCardPaymentMethod = {
  type: "CARD",
  parameters: {
    allowedCardNetworks: ["VISA", "MASTERCARD"],
    allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"]
  }
};
function createAndAddButton() {
  if (googlePayClient) {
    const googlePayButton = googlePayClient.createButton({
      buttonColor: "default",

      buttonType: "long",

      onClick: processPayment
    });

    //setGPayBtn(googlePayButton);
  }
}
function processPayment() {
  console.log("test");
  const tokenizationSpecification = {
    type: "PAYMENT_GATEWAY",
    parameters: {
      gateway: "stripe",
      "stripe:version": "v3",
      "stripe:publishableKey": "pk_test_35p114pH8oNuHX72SmrvsFqh00Azv3ZaIA"
    }
  };
  const cardPaymentMethod = {
    type: "CARD",
    tokenizationSpecification: tokenizationSpecification,
    parameters: {
      allowedCardNetworks: ["VISA", "MASTERCARD"],
      allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
      billingAddressRequired: true,
      billingAddressParameters: {
        format: "FULL",
        phoneNumberRequired: true
      }
    }
  };
  const transactionInfo = {
    totalPriceStatus: "FINAL",
    totalPrice: "123.45",
    currencyCode: "USD"
  };


  const merchantInfo = {
    // merchantId: '01234567890123456789', Only in PRODUCTION
    merchantName: "Example Merchant Name"
  };
  const paymentDataRequest = {
    ...googlePayBaseConfiguration,
    ...{
      allowedPaymentMethods: [cardPaymentMethod],
      transactionInfo,
      merchantInfo
    }
  };
  googlePayClient
  .loadPaymentData(paymentDataRequest)
  .then(function (paymentData) {
    console.log(paymentData);
  })
  .catch(function (err) {
    console.log(err);
  });
}

const googlePayBaseConfiguration = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [baseCardPaymentMethod]
};

  const EmptyCart = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">Your Cart is Empty</h4>
            <Link to="/" className="btn  btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> Continue Shopping
            </Link>
          </div>
        </div>
      </div>
    );
  };


  const ShowPDF =()=>{

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">PDF Generation</h4>
            <div className="App container mt-5">
       <button class="btn btn-primary" onClick={this.printDocument}>Export To PDF</button>
      <div id="divToPrint" className="m-3">
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="d-flex flex-row p-2">
                    <div class="d-flex flex-column"> <span class="font-weight-bold">Tax Invoice</span> <small>INV-001</small> </div>
                    
                </div>
                
                <hr />
                <div class="table-responsive p-2">
                    <table class="table table-borderless">
                        <tbody>
                            <tr class="add">
                                <td>To</td>
                                <td>From</td>
                            </tr>
                            <tr class="content">
                                <td class="font-weight-bold">Google <br />Attn: Jassa Smith Pymont <br />Australia</td>
                                <td class="font-weight-bold">Facebook <br /> Attn: Jassa Right Polymont <br /> USA</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div class="products p-2">
                    <table class="table table-borderless">
                        <tbody>
                            <tr class="add">
                                <td>Description</td>
                                <td>Days</td>
                                <td>Price</td>
                                <td class="text-center">Total</td>
                            </tr>
                            <tr class="content">
                                <td>Website Redesign</td>
                                <td>15</td>
                                <td>$1,500</td>
                                <td class="text-center">$22,500</td>
                            </tr>
                            <tr class="content">
                                <td>Logo Identity</td>
                                <td>10</td>
                                <td>$1,500</td>
                                <td class="text-center">$15,000</td>
                            </tr>
                            <tr class="content">
                                <td>Marketing Collateral</td>
                                <td>3</td>
                                <td>$1,500</td>
                                <td class="text-center">$4,500</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div class="products p-2">
                    <table class="table table-borderless">
                        <tbody>
                            <tr class="add">
                                <td></td>
                                <td>Subtotal</td>
                                <td>GST(10%)</td>
                                <td class="text-center">Total</td>
                            </tr>
                            <tr class="content">
                                <td></td>
                                <td>$40,000</td>
                                <td>2,500</td>
                                <td class="text-center">$42,500</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr />
                <div class="address p-2">
                    <table class="table table-borderless">
                        <tbody>
                            <tr class="add">
                                <td>Bank Details</td>
                            </tr>
                            <tr class="content">
                                <td> Bank Name : ADS BANK <br /> Swift Code : 00220022 <br /> Account Holder : Jassa Pepper <br /> Account Number : 6953PO789 <br /> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
        </div>
    
      </div>
   
          </div>
        </div>
      </div>
    );

  }

  const addItem = (product) => {
    dispatch(addCart(product));
  };
  const removeItem = (product) => {
    dispatch(delCart(product));
  };

    const ShowCart = () => {
    let subtotal = 0;
    let tax = 0.0;
    let totalItems = 0;
    //const item={};
    
    state.map((item) => {
      //console.log("Line 40 " + item.price); //totalPrice
      return (subtotal += item.price * item.qty);
    });

    state.map((item) => {
      return (totalItems += item.qty);
    });

    const generateInvoice=()=>{
      var total = 1500
      var advance = 500
        const doc = new jsPDF();
        doc.setFontSize(22);
        var width = doc.internal.pageSize.getWidth()
        doc.text('INVOICE', width/2, 10, { align: 'center' })
        doc.setFontSize(18);
doc.text('SUNDAR', 140, 18);
doc.setFontSize(11);
doc.text('Order Date: 03/09/2023',5, 20); 
doc.text('Order No: 125410354',5, 25); 
doc.text('Customer Name: Akshay kumar',5, 30); 
doc.text('Address: 23/1 Gitanjali Apartment, Kolkata 45',5, 35); 
doc.text('Contact No: 9874584210',5, 40); 
doc.text('SilBagan, Haldar Bagan.',140, 25); 
doc.text('Chinsurah. Hooghly, PIN 712101',140, 30); 
doc.text('Contact: 7003895249',140, 35); 
doc.text('Website: https://printsundar.com',140, 40); 
doc.line(5, 45, 200, 45);
doc.rect(5, 50, 195, 110);
doc.text('SL #',8, 55);
doc.text('1',8, 75);
doc.line(5, 60, 200, 60);
doc.text('Description',60, 55);
doc.text('Order Name : Visiting Card ',30, 75);
doc.text('Card Type : Corner Rectangle ',30, 80);
doc.text('Card Size : 8.9 x 5.1 ',30, 85);
doc.text('Media Type : Paper ',30, 90);
doc.text('Quality : Glossy ',30, 95);
doc.text('Quantity : 100 ',30, 100);
doc.text(total +'/-' ,160, 80);
doc.text('Tax  ',30, 120);
doc.text('Advance: '+ advance +'/-',30, 153);
doc.text(0.18*total +'/-' ,160, 120);
doc.text('Price',175, 55);

doc.line(22, 50, 22, 145);

doc.line(150, 50, 150, 145);

doc.line(5, 145, 200, 145);
doc.text('Total Amount = ' + (total + 0.18*total  )+'/-',140, 153); 
doc.text('Total Due = ' + (total + 0.18*total - advance )+'/-',140, 158); 
doc.save('demo.pdf');

/* 
          //get html
          const pdfTable = document.getElementById('Print');
          //html to pdf format
          var html = htmlToPdfmake(pdfTable.innerHTML);
        
          const documentDefinition = { content: html };
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
          pdfMake.createPdf(documentDefinition).open();
        */
          console.log("generateInvoice Clicked...");

    
    }
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    var templateParams = {
      name: 'James',
      notes: 'Check this out!'
  };

  const options = {
    method: 'POST',
    headers: {accept: 'application/json', 'Content-Type': 'application/json'}
  };

  const DisplayPaymentUI = async(e)=>{
      

  }


  var templateParams = {
    message : 'Hello Dina',
    name: 'Hi Dina',
    email: 'barunbairagi@gmail.com',
    to_mail: 'barunbairagi@gmail.com',
   };

  const SendMail = async(e)=>{
    
    console.log("Ln 248 SendMail Send called.. ");
    emailjs.send('service_5l4zgi1','sunder_template_i9kyzm1', templateParams, 'xkVfdOdrH64JDAp3E')
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });

  }

  //  const handleSubmit = async (e) => {

  //   console.log("Ln 58 handleSubmit called.. ");
      
  //  if(state.length > 0 )
  //  {
  //    const item = state[0];
  //    console.dir(item);
  //    //console.log("Ln 63 state.lenght > 0 " + item.cardType);
  //    e.preventDefault();
        
  //   try {
  //     let res = await fetch("http://153.92.4.115:8080/loginAPI/addPrintOrder", {
  //       method: "POST",
  //       headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
  //       body: JSON.stringify({  
  //           "qrcodeImagePath":item.QRImageFile,
  //           "advance":"500",
  //           "backImagePath":item.backImageFile,
  //           "bookingDatetime":new Date().getDay() +"/"+new Date().getMonth()+"/"+ new Date().getFullYear(),
  //           "bookingType":"Print",
  //           "boolUploadBackImage":item.boolUploadBackImage,
  //           "boolUploadFrontImage":item.boolUploadFrontImage,
  //           "cardSize":item.cardSize,
  //           "cardType":item.cardType,
  //           "comments":"Hello Comments",
  //           "embossing":item.embossing,
  //           "embossingSingleBoth":item.embossingSingleBoth,
  //           "gsmValue":item.gsmValue,
  //           "lamination":item.lamination,
  //           "laminationSingleBoth":item.laminationSingleBoth,
  //           "mediaType":item.mediaType,
  //           "orderName":item.orderName,
  //           "phoneNo":"9433510469",
  //           "price":item.price,
  //           "qRCode":item.qRCode,
  //           "qRSingleBoth":item.qRSingleBoth,
  //           "quality":item.quality,
  //           "quantity":item.quantity,
  //           "registrationNo":"AK00125",
  //           "specialColor": item.specialColor,
  //           "specialColorSingleBoth":item.specialColorSingleBoth,
  //           "spotUV":item.spotUV,
  //           "spotUVSingleBoth":item.spotUVSingleBoth,
  //           "task_owner":"Barun",
  //           "task_status":"Pending",
  //           "task_type":"Design",
  //           "texture":item.texture,
  //           "textureSingleBoth":item.textureSingleBoth,
  //           "transparent":item.transparent,
  //           "transparentSingleBoth":item.transparentSingleBoth,
  //           "userName":"Dheeman Bairagi"            
  //       }),
  //     });
  //     let resJson = await res.json();
  //     if (res.status === 200 || res.status === 201) {
  //        //setTaskData([]);
  //         console.log("Print Order added successfully");
  //         console.log("Line 116  printOrderId = " + resJson.printOrderId);
  //         console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //         console.log("item.frontImageFile" + item.frontImageFile);
  //         //console.log("item.frontImageFile" + item.backImageFile);
  //         const data = new FormData();
  //         data.append('files', item.frontImageFile);
  //         data.append('print_id', resJson.printOrderId);
                   
  //         let res = await fetch(
  //           "http://153.92.4.115:8080/loginAPI/uploadMultipleFiles",
  //           {
  //             method:'POST',
  //             mode:'no-cors',
  //             body:data,
  //             headers: {
  //               'content-type': item.frontImageFile.type,
  //               'content-length': `${item.frontImageFile.size}`, // 👈 Headers need to be a string
  //             }
              
  //           }
  //         );
  //         let responseJson = await res.json();
  //         if (responseJson.status == 1) {
  //           alert('Upload Successful');
  //         }
  //        else {
  //         //if no file selected the show alert
  //         alert('Please Select File first');
  //       }
      
  //         //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

  //         //setMessage("Task added successfully");
  //         //toastr.success('New task added successfully!', {position : 'top-right', heading: 'Done'});    
       
  //     } else {
  //       console.log("Some error occured");
  //       //setMessage("Error occured...");
  //       //toastr.error('Error to add task!', {position : 'top-right', heading: 'Failed'});
  //     }
    
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
   
  // };
   
  const isDataValid = () => {

    
  }


  const handleBookingAmount= (evt, param)=>{

    SetBookingAmount(evt.target.value);
    //console.log("BookingAmount = " + evt.target.value);
  
  }

  const handleCheckOutButton= (evt, param)=>{

   
    console.log("handleCheckOutButton called... Booking Amount = "+ bookingAmount);
    if (isAuthenticated) {
      if(fullPaymentChecked)
       { 
        //let fullPrice = Math.round(price * 0.6);
        let fullPrice = Math.round(price);
        localStorage.setItem("AdvancePaymentFull", fullPrice);
        localStorage.setItem("IsFullPaid", true);
       }
       
       else{
          localStorage.setItem("AdvancePayment", bookingAmount);
          localStorage.setItem("IsFullPaid", false);
         
      }
    }
  }
  

  const handleCheckChange = (event, param) => {
    
   console.log(" handleCheckChange =" + event.target.checked);
    SetFullPaymentChecked(event.target.checked);
    console.log(" Value of fullPaymentChecked =" + fullPaymentChecked);
    //console.log(" Line 527 Front Image Path " + item.frontImagePath);
   
  };

    return (
      <>
        <section className="h-100 gradient-custom">
          <div id="Print" className="container bg-primary text-black py-2">
            <div className="row d-flex justify-content-center my-4">
              <div className="col-md-8">
                <div className="card mb-4">
                  <div className="card-header py-3">
                    <h4 className="mb-0">Order Summary</h4>
                    </div>
                  <div className="card-body">
                    {state.map((item) => {
                        
                    console.log(" Line 543 Front Image Path " + item.frontImagePath);

                     return (
                        <div key={item.id}>
                          <div className="row d-flex align-items-center">
                            <div className="col-lg-9 col-md-12">
                              <div
                                className="bg-image rounded"
                                data-mdb-ripple-color="light"
                              >
                                <img
                                  src= {item.frontImagePath}   // //item.frontImagePath
                                  // className="w-100"
                                  alt="image"
                                  width={400}
                                  height={300}
                                />
                              </div>
                            </div>

                            <div className="col-lg-9 col-md-12 py-3">
                              <p>
                                <strong>Order Name : {item.orderName}</strong>
                                </p>
                                <p>
                                <strong>Card Type : {item.cardType}</strong>
                                </p>
                                <p>
                                <strong>Card Size : {item.cardSize}</strong>
                                </p>
                                <p>
                                <strong>Media Type : {item.mediaType}</strong>
                                </p>
                                <p>
                                <strong>Quality : {item.quality}</strong>
                                </p>
                                <p>
                                <strong>Quantity : {item.quantity}</strong>
                                </p>
                                <p>
                                <strong> Front Image : {item.frontImagePath}</strong>
                                </p>
                                <p>
                                <strong>Back Image : {item.backImagePath}</strong>
                                </p>
                                <p>
                                <strong>QR Image : {item.QRCodeImagePath}</strong>
                                </p>
                                <p>
                                <strong>qRCode : {item.qRCode}</strong>
                                </p>
                                <p>
                                <strong>GSM Value : {item.gsmValue}</strong>
                                </p>
                                                         
                            </div>

                          </div>
                          <hr className="my-4" />
                         </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
              <div className="card mb-4">
                  <div className="card-header py-3 bg-light">
                    <h5 className="mb-0">Pricing Details</h5>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                       Total Price <span>Rs {Math.round(price)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                        Delivery Charges
                        <span>Rs {Math.round(price * 0)}</span>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                        <div>
                          <strong>Net Payable Amount</strong>
                        </div>
                        <span>
                          <strong>Rs {Math.round(price + price * 0)}</strong>
                        </span>
                      </li>
                    </ul>
                    <div className="container bg-primary text-white my-0 py-3">
        <h4 className="text-center">Select Payment Option</h4>
        <h6 className="text-center"> Full Payment/ Booking Amount = Rs {Math.round(price + price * 0) *.6}/- (Must be min 60% of total amount)</h6>
        <hr />
          <form>
          <div className="row g-3">
            <div className="col-sm-6 my-1">
          <Row>
      <Col>
       <div class="form-check pt-4">
       <input class="form-check-input" 
              type="checkbox" 
              name="checked"
              checked={fullPaymentChecked}
              onChange={handleCheckChange}
      /> 
       <label class="form-check-label" for="flexCheckChecked" >
       Full Payment
       </label>
       </div>
       </Col>
           
      </Row> 
      </div>
      </div>
      
      <div className="row g-6">
      <div className="col-sm-8 my-2">
      { !fullPaymentChecked && 
      <Row>
      <Col>
      <label htmlFor="display-4">Booking Amount</label>
      <input
                      type="number"
                      onChange={handleBookingAmount}
                      className="form-control"
                      name="bookingAmount"
                      id="bookingPrice"
                      value={bookingAmount}
                      placeholder=""
                    />
       </Col>
           
      </Row> 
    }
    
                  </div>
                  
                  { (bookingAmount >= Math.round(price * 0.6) || fullPaymentChecked) && 
                  <Link
                      to="/CheckoutProduct"
                      className="btn btn-dark btn-lg btn-block"
                      onClick={handleCheckOutButton}
                    >
                      Go to checkout
                    </Link>
                   }
                  </div>
                
                  </form> 
                  
                  </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>
        
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container bg-primary text-white my-2 py-2">
        <h1 className="text-center">Cart</h1>
        <hr />
        
            {isAuthenticated ? 
            
            <ShowCart /> : <EmptyCart />}
          
       </div>
       <div className="min-h-screen bg-gray-100">
      
    </div>
      <Footer />
    </>
  );
};

export default Cart;
