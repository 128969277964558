import React from 'react'
import { Footer, Navbar } from "../components";

import {img} from "react-bootstrap/Image";

import AboutUsImage from '../images/about us.png';

const ShippingPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="row">
    <div className="col-12 ">
    
     <img className="card-img-top img-fluid" src={AboutUsImage} alt="" height={160} />
      </div> 
      </div>
      <div className="container my-3 py-3">
      
      <h2>Greetings User</h2>
            <h3>Shipping Policy</h3>
            <div class="terms-text">
            <p className="lead text-start">Shipping is free for all products across India.
            Once the design is approved and the print order is placed, the estimated delivery date of the product 
            will be intimated to the customer immediately through mail/message if the advance is deposited.The delivery 
            date can be determined depending on the quantity and quality (Lamination, UV Coat, Texture Print, Heat Print,
             Embossing, Special color, etc.) of the work. Otherwise, in case of general order, 
             the ordered goods will be delivered within seven days.</p>

<p className="lead text-start">Frequently asked questions.</p>

<p className="lead text-start">Q: Will my order be trackable? 
                               A: Yes. All orders are trackable, and you will receive tracking details once the order is shipped from our manufacturing facility. For COD orders, you will receive the tracking number via SMS.</p>

<p className="lead text-start">Q: Do I get tracking info? Where can I find it? 
                              A: Once your order is shipped, we’ll send you a confirmation email that includes the tracking details along with the estimated arrival date. You can also go to your order history to find out more. For COD orders, track the order from the tracking number sent via SMS.</p>

<p className="lead text-start">Q: Which carriers do you use? 
                              A: We determine which carrier to use for your order based on a variety of factors, such as products purchased, shipping address, and size/weight of the order.</p>

<p className="lead text-start">Q: Do I get tracking info? Where can I find it? 
                              A: Once your order is shipped, we’ll send you a confirmation email that includes the tracking details along with the estimated arrival date. You can also go to your order history to find out more. For COD orders, track the order from the tracking number sent via SMS.</p>

<p className="lead text-start">Q: My package was supposed to arrive today, but I have not received it yet. What should I do? 
                               A: You can track your order with the tracking information in your Order History under My Account. For COD orders, use the tracking number sent via SMS. You may also contact us on the customer care helpline number – we’ll be happy to help.</p>






            </div>
            </div>
      <Footer />
    </>
  )
}

export default ShippingPolicy