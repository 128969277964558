import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";

import StandardImage from '../images/Standard.PNG';
import NonTearable from '../images/NonTearable.png';
import InvitationCard from '../images/QRCoadPremiuglossy.png';
import SpotUVspecialVisitingCard from '../images/SpotUVspecialVisitingCard.png';

import Magneticvisitingcard from '../images/Magnetic.png';
import ThreeDEffectvisitingcard from '../images/3DEffect.png';
import UniqueShapevisitingcard from '../images/UniqueShape.png';
import Photographicvisitingcard from '../images/photographic.png';



import Transparentvisitingcard from '../images/TransparentVC.png';
import Spot_UV from '../images/Spot_UV.png';
import MatteVC from '../images/MatteVC.png';
import SpecialcolourVC from '../images/SpecialcolourVC.png';


import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { Link } from "react-router-dom";
import {img} from "react-bootstrap/Image";
import { useAuth0 } from "@auth0/auth0-react";


import { Navbar, Main, Product, Footer } from "../components";

import VisitingCardsImg from '../images/VisitingCards.PNG';

const VisitingCards = () => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(data);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;
  const dispatch = useDispatch();
  const addProduct = (product) => {
    dispatch(addCart(product))
  }

  useEffect(() => {
    /*const getProducts = async () => {
      setLoading(true);
      const response = await fetch("https://fakestoreapi.com/products/");
      if (componentMounted) {
        setData(await response.clone().json());
        setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
    */
  }, []);

  const Loading = () => {
   
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={692} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = data.filter((item) => item.category === cat);
    setFilter(updatedList);
  }
  const ShowByShape = () => {
    return (
      <>
        
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={StandardImage} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Standard Card</h5>
               </div>
               
                {isAuthenticated &&
            
              <Link to={"/SubmitOrder/"  } state={{title: 'Standard_VC'}} className="btn btn-dark m-1">
                    Buy Now
              </Link>
                }
               { !isAuthenticated &&
               
                  // move to Login screen
                  
                  <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>

               }
               
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={NonTearable} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Non Tearable Card</h5>
                </div>
                
                {
                  isAuthenticated &&
                  <Link to={"/SubmitOrder/"  } state={{title: 'NonTearable_VC'}} className="btn btn-dark m-1">
                  Buy Now
            </Link>

                }
                { !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>

            }
             
             
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={InvitationCard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">QR Code Premium Card</h5>
                </div>
                
                {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'QRCodePremium_VC'}} className="btn btn-dark m-1">
Buy Now
</Link>
                }
                

{ !isAuthenticated &&
               
  // move to Login screen
  
  <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>

}
                
              
             
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={SpotUVspecialVisitingCard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Velvet Touch Card</h5>
                </div>
                
                {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'Velvet_Touch_VC'}}  className="btn btn-dark m-1">
Buy Now
</Link>
                }
                  

{ !isAuthenticated &&
               
  // move to Login screen
  
  <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>

}
                  
                
          
              
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  const ShowPaperTexture = () => {
    return (
      <>
        
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={Transparentvisitingcard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Transparent Card</h5>
                  </div>
                 
                  {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'Transparent_VC'}}  className="btn btn-dark m-1">
Buy Now
</Link>

                  }
                  
{ !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
                
          
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={Spot_UV} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Spot UV Card</h5>
                </div>
               
                {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'SpotUV_VC'}}  className="btn btn-dark m-1">
Buy Now
</Link>


                }
                                  
{ !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
              
            
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={MatteVC} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Matte Card</h5>
                </div>
               
                { isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'Matte_VC'}} className="btn btn-dark m-1">
Buy Now
</Link>
                }
                { !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
                
            
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={SpecialcolourVC} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Foil Printed Card</h5>
               </div>
             
               {isAuthenticated &&
                   <Link to={"/SubmitOrder/"  } state={{title: 'Special_Color_VC'}}  className="btn btn-dark m-1">
                   Buy Now
             </Link>

               }
               
               { !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
          
           
              
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  // Show Special VC

   const ShowSpecialVisitingCard = () => {
    return (
      <>
        
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={Magneticvisitingcard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Magnetic Card</h5>
                  </div>
                 
                  {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'Magnetic_VC'}}  className="btn btn-dark m-1">
Buy Now
</Link>

                  }
                  
{ !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
                
          
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={ThreeDEffectvisitingcard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">3D Effect Card</h5>
                </div>
               
                {isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: '3D_Effect_VC'}}  className="btn btn-dark m-1">
Buy Now
</Link>


                }
                                  
{ !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
              
            
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={UniqueShapevisitingcard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Unique Shape Card</h5>
                </div>
               
                { isAuthenticated &&

<Link to={"/SubmitOrder/"  } state={{title: 'UniqueShape_VC'}} className="btn btn-dark m-1">
Buy Now
</Link>
                }
                { !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
                
            
              
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-150">
              <img className="card-img-top img-fluid" src={Photographicvisitingcard} alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Photographic Card</h5>
               </div>
             
               {isAuthenticated &&
                   <Link to={"/SubmitOrder/"  } state={{title: 'Photographic_VC'}}  className="btn btn-dark m-1">
                   Buy Now
             </Link>

               }
               
               { !isAuthenticated &&
               
               // move to Login screen
               
               <button type="button"  class="btn btn-dark m-1" name ="price" onClick={loginWithRedirect} >Buy Now</button>
             
             }
          
           
              
            </div>
          </div>
        </div>     
     
      </>
    );
  };

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  return (
    <>
     <Navbar />
    <div className="container my-3 py-8">
    <div className="row">
    <div className="col-12 ">
     <img src={VisitingCardsImg} class="img-fluid" alt="header image"/>
      </div> 
      </div>
    </div>
    <div className="container my-3 py-3">
    <div className="row">
      <div className="col-12">
         <h2 className="display-12 text-left">Cards By Shape</h2>
         <h3 className="display-10 text-left">Select from various shapes & sizes</h3>
        <hr />
      </div>
    </div>
    <div className="row justify-content-center">
      {loading ? <Loading /> : <ShowByShape />}
    </div>
    </div>

    <div className="container my-3 py-3">
    <div className="row">
      <div className="col-12">
         <h2 className="display-12 text-left">Shop by papers & textures</h2>
         <h3 className="display-10 text-left">Select from various papers & textures</h3>
        <hr />
      </div>
    </div>
    <div className="row justify-content-center">
      {loading ? <Loading /> : <ShowPaperTexture />}
    </div>
    <div className="row justify-content-center">
      {loading ? <Loading /> : <ShowSpecialVisitingCard />}
    </div>
    </div>
    <Footer />
</>
);
};

export default VisitingCards;
